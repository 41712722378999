import { Page, Document, pdfjs } from "react-pdf";
import {
  StyledPdfPreviewContainer,
  StyledPdfPreviewWrapper,
  StyledProcessBtn,
  StyledProcessBtnContainer,
  StyledSelectOrder,
  StyledSelectPageContainer,
  StyledSelectPageInnerContainer,
  StyledSelectPageTitle,
  StyledSelectedEffect,
  StyledSinglePageContainer,
} from "./Styles/StyledBills";
import {useEffect, useState} from "react";
import { useDoxleThemeStore } from "../DoxleGeneralStore/useDoxleThemeStore";
import { useBillStore } from "./Store/useBillStore";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();

export function SelectPage() {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));

  const { pdfFiles, setStage } = useBillStore((state) => ({
    pdfFiles: state.pdfFiles,
    setStage: state.setCurrentStage,
  }));

  const [pageNums, setPageNums] = useState<number>(0);
  const [selectedPages, setSelectedPages] = useState<number[]>([]);
  const [selectOrder, setSelectOrder] = useState<number[][]>([]);
  // const [selectedCount, setSelectedCount] = useState<number>(0);
  useEffect(()=>{
    console.log('selectOrder', selectOrder)
  },[selectOrder])
  const handlePDFLoadSuccess = ({ numPages }: { numPages: number }) => {
    setPageNums(numPages);
    // let orders: number[][] = [];
    // for (let i = 0; i < numPages; i++) {
    //   orders.push([i, 0]);
    // }
    // setSelectOrder(orders);
  };

  const handleProcess = () => {
    if (selectedPages.length > 0) {
      setStage("processing");
    }
  };

  const getFileExtension = (filename: string) => {
    return filename.split(".").pop();
  };

  const handleSelectPage = (e: React.MouseEvent<HTMLDivElement>, page: number) => {
    if (!selectedPages.includes(page)) {
      setSelectOrder((prev: number[][])=> {
        if (prev.length && e.ctrlKey) {
          const lastItem: number[] = [...prev[prev.length - 1], page]
          prev.splice(prev.length - 1, 1 , lastItem)
          return [...prev]
        } else {
          return [...prev, [page]]
        }
      })
      setSelectedPages(prev => [...prev, page]);
    } else {
      setSelectOrder(prev => {
        const newList: number[][] = []
        prev.forEach(a => {
          if (!a.includes(page)) newList.push(a)
          else if (a.length > 1) newList.push(a.filter(i=> i !== page))
        })
        return newList
      })
      setSelectedPages(prev => [...prev.filter(i=> i !== page)]);
    }
  };

  // const handleAddOrder = (page: number) => {
  //   let orders = [...selectOrder];
  //   orders[page][1] = selectedCount + 1;
  //   setSelectOrder(orders);
  // };

  const handlRemoveOrder = (page: number) => {
    let orders = [...selectOrder];
    let oldOrder = orders[page][1];
    orders[page][1] = 0;
    for (let i = 0; i < pageNums; i++) {
      if (orders[i][1] > oldOrder) {
        orders[i][1]--;
      }
    }
    setSelectOrder(orders);
  };

  return (
    <StyledSelectPageContainer>
      <StyledSelectPageInnerContainer>
        <StyledProcessBtnContainer>
          <StyledProcessBtn
            $themeColor={doxleThemeColor}
            onClick={handleProcess}
          >
            Process
          </StyledProcessBtn>
        </StyledProcessBtnContainer>
        <StyledPdfPreviewWrapper>
          {getFileExtension(pdfFiles[0]?.name ?? "") === "pdf" ? (
            <Document onLoadSuccess={handlePDFLoadSuccess} file={pdfFiles[0]}>
              <StyledPdfPreviewContainer $themeColor={doxleThemeColor}>
                {Array.from(Array(pageNums)).map((page, i) => (
                  <StyledSinglePageContainer
                    $themeColor={doxleThemeColor}
                    onClick={(e) => handleSelectPage(e, i)}
                  >
                    {selectedPages.includes(i) && (
                      <StyledSelectedEffect $themeColor={doxleThemeColor}>
                        <StyledSelectOrder $themeColor={doxleThemeColor}>
                          {selectOrder.findIndex(a=> a.includes(i)) + 1}
                        </StyledSelectOrder>
                      </StyledSelectedEffect>
                    )}
                    <Page
                      pageNumber={i + 1}
                      width={480}
                      renderTextLayer={false}
                      renderAnnotationLayer={false}
                    />
                  </StyledSinglePageContainer>
                ))}
              </StyledPdfPreviewContainer>
            </Document>
          ) : (
            "image"
          )}
        </StyledPdfPreviewWrapper>
      </StyledSelectPageInnerContainer>
    </StyledSelectPageContainer>
  );
}
