import { IconButton, IconButtonProps, styled } from "@mui/material";

import { motion } from "framer-motion";
import React from "react";
interface Props extends IconButtonProps {
  buttonSize?: number;
  buttonFontColor?: string;
  bgColor?: string;
  iconSource: any;
  buttonText?: string;
  selectedEffect?: boolean; //pass the boolean to indicate the button is selected
  disableAnimation?: boolean;

  buttonWrapperStyle?: Omit<
    React.CSSProperties,
    "width" | "height" | "borderRadius"
  >;
}

const DoxleIconButton = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      buttonSize,
      buttonFontColor,
      bgColor,
      iconSource,
      buttonText,
      selectedEffect,
      disableAnimation,

      buttonWrapperStyle,
      ...rest
    }: Props,
    innerRef
  ) => {
    return (
      <StyledIconButton
        ref={innerRef}
        {...rest}
        $disableAnimation={disableAnimation}
        $selectedEffect={selectedEffect}
        sx={{
          "&.MuiButtonBase-root": {
            // justifyContent: "center",
            // alignItems: "center",
            // overflow: "hidden",
            // whiteSpace: "normal",
            // textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            padding: "1px !important",
          },
          height: buttonSize ? `${buttonSize / 10}rem` : "7rem",
          width: buttonSize ? `${buttonSize / 10}rem` : "7rem",
          borderRadius: buttonSize
            ? `${buttonSize / 10 / 2}rem !important`
            : "3.5rem !important",
          backgroundColor: `${bgColor ? bgColor : "transparent"} !important`,

          ...buttonWrapperStyle,
          ...rest.sx,
        }}
      >
        {iconSource}
      </StyledIconButton>
    );
  }
);

export default DoxleIconButton;

const AnimatedIconButton = motion(IconButton);
//*Animation
const btnAnimatedVariants = {
  initial: {
    y: 0,
    scale: 1,
    opacity: 0,
  },
  entering: {
    y: 0,
    opacity: 1,
    scale: 1,
    transition: {
      duration: 0.4,
    },
  },
  hovering: {
    y: 0,
    scale: 0.9,
    opacity: 0.8,
    transition: {
      duration: 0.2,
    },
  },
  selected: {
    y: -5,
    scale: 1.2,

    transition: {
      duration: 0.2,
    },
  },
  exit: {
    scale: 0,
    opacity: 0,
  },
};
const StyledIconButton = styled(
  React.forwardRef<HTMLButtonElement, any>(
    (
      {
        $disableAnimation,
        $selectedEffect,
        ...props
      }: Omit<
        IconButtonProps,
        "onDragStart" | "onAnimationStart" | "onDragEnd" | "onDrag"
      > & { $selectedEffect?: boolean; $disableAnimation?: boolean },
      innerRef
    ) => (
      <AnimatedIconButton
        {...props}
        ref={innerRef}
        variants={btnAnimatedVariants}
        initial="initial"
        animate={Boolean($selectedEffect ?? false) ? "selected" : "entering"}
        whileHover={$disableAnimation ? undefined : "hovering"}
        exit="exit"
      />
    )
  )
)`
  && {
    &:hover {
      transform: scale(1.1);
    }
  }
`;
