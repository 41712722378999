import { create } from "zustand";

interface PageTransitionContextStore {
  preset: string;
  enterAnimation: string;
  exitAnimation: string;
  setPreset: (preset: string) => void;
  setEnterAnimation: (enterAnimation: string) => void;
  setExitAnimation: (exitAnimation: string) => void;
}

export const usePageTransitionContextStore = create<PageTransitionContextStore>(
  (set) => ({
    preset: "roomToBottom",
    enterAnimation: "moveFromLeft",
    exitAnimation: "moveToLeft",
    setPreset: (preset: string) => set((state) => ({ preset: preset })),
    setEnterAnimation: (enterAnimation: string) =>
      set((state) => ({ enterAnimation: enterAnimation })),
    setExitAnimation: (exitAnimation: string) =>
      set((state) => ({ exitAnimation: exitAnimation })),
  })
);
