import {useNavigate} from "react-router-dom";
import React, {useMemo} from "react";
import {useRetrieveBillsList} from "../Services/QueryHooks/useBillsQueries";
import {Virtuoso} from "react-virtuoso";
import {useDoxleThemeStore} from "../DoxleGeneralStore/useDoxleThemeStore";
import {shallow} from "zustand/shallow";
import {BillLine, StyledBillsListContainer, StyledBillsListHeader, StyledBillsListPage} from "./styledComponents";
import {useDoxleCurrentContextStore} from "../DoxleGeneralStore/useDoxleCurrentContext";
import Loading from "../Utilities/Lottie/Loading";
import {Bill} from "../Models/bills";

const BillsListScreen = () => {
  const {company, search} = useDoxleCurrentContextStore(state=> ({
    company: state.currentCompany,
    search: state.currentSearchText
  }), shallow)
  const billQuery = useRetrieveBillsList(search ? {search} : {});
  const bills = useMemo(()=>billQuery.isSuccess ? billQuery.data?.data : [],[billQuery.data])
  const styleProps = useDoxleThemeStore(state => ({
    $themeColor: state.doxleThemeColor,
    $doxleFont: state.doxleFont,
    $currentTheme: state.currentTheme
  }), shallow)
  const navigate = useNavigate()
  if (billQuery.isSuccess && bills.length === 0) navigate('Upload/')
  if (billQuery.isLoading) return <Loading/>
  if (billQuery.isError) return <>Error Fetching Bill List</>
  return(
    <StyledBillsListPage {...styleProps}>
      <StyledBillsListHeader{...styleProps}>
        {company?.name} Bills
      </StyledBillsListHeader>
      <StyledBillsListContainer {...styleProps}>
        <Virtuoso
          style={{ width: "100%", height: "100%" }}
          totalCount={bills.length}
          data={bills}
          itemContent={(index: number, bill: Bill) => (
            <BillLine onClick={()=> navigate(`Review/${bill.billId}`)} {...styleProps}>
              <span className={'file'}>{bill.fileName}</span>
              <span className={'amount'}>{bill.billTotal ?? '$0.00'}</span>
              <span className={'status'}>{bill.status}</span>
            </BillLine>
          )}
        />
      </StyledBillsListContainer>
    </StyledBillsListPage>
  )
}
export default BillsListScreen
