import { useCallback } from "react";
import DoxleSideScreen from "../../DoxleDesignPattern/DoxlleSideScreen/DoxleSideScreen";
import { shallow } from "zustand/shallow";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { StyledDoxleSideMenuContainer } from "./StyledComponentDoxleSideMenu";
import { TRgbaFormat, editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import { useDoxleMainMenuStore } from "../../DoxleGeneralStore/useDoxleMainMenuStore";
import { SideMenuTable } from "../DoxleTopMenu/NavBarEllipsis/SideMenuTable";

type Props = {};

const DoxleSideMenu = (props: Props) => {
  const { showSideMenu, setShowSideMenu } = useDoxleMainMenuStore(
    (state) => ({
      showSideMenu: state.showSideMenu,
      setShowSideMenu: state.setShowSideMenu,
    }),
    shallow
  );
  const { doxleThemeColor, doxleFont, currentTheme } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    }),
    shallow
  );

  const handleCloseMenu = useCallback(() => {
    setShowSideMenu(false);
  }, []);
  return (
    <DoxleSideScreen
      openSideScreen={showSideMenu}
      handleCloseSideScreen={handleCloseMenu}
      containerMinWidthInPixel="327px"
      containerWidthInRatio="50%"
      containerLeftPosInPixel="0px"
      containerWidthInPixel="239px"
      expandable={false}
      hasBackDrop
      backdropStyle={{
        backgroundColor: editRgbaAlpha({
          rgbaColor: doxleThemeColor.primaryBackdropColor as TRgbaFormat,
          alpha: "0.4",
        }),
      }}
      children={
        <StyledDoxleSideMenuContainer
          $currentTheme={currentTheme}
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          <div style={{ height: "100%", width: "90%" }}>
            <SideMenuTable />
          </div>
        </StyledDoxleSideMenuContainer>
      }
    />
  );
};

export default DoxleSideMenu;
