import axios, {
  AxiosError,
  AxiosResponse,
} from "axios";
import { ReactNode, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDoxleAuthStore } from "../DoxleGeneralStore/useDoxleAuthStore";
import { useDoxleCurrentContextStore } from "../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import useDoxleNotificationStore from "../DoxleGeneralStore/useDoxleNotificationStore";
// import useDoxleNotificationStore from "../DoxleGeneralStore/useDoxleNotificationStore";

const local: boolean = false;

export const baseAddress: string = local
  ? "http://127.0.0.1:5000/"
  : "https://bills-api.doxle.com/";

const BillsAPI = axios.create({
  baseURL: baseAddress,
  withCredentials: true,
});

export default BillsAPI;

interface Props {
  children: ReactNode;
}
const AxiosInterceptor = ({ children }: Props) => {
  const companyId = useDoxleCurrentContextStore(
    (state) => state.currentCompany?.companyId,
    shallow
  );
  const setLoggedIn = useDoxleAuthStore((state) => state.setLoggedIn, shallow);
  const showNotification = useDoxleNotificationStore(state => state.showNotification, shallow);
  const navigate = useNavigate();
  const pathname = window.location.pathname;

  const isGreetingPage = Boolean(
    pathname === "/Login/" ||
    pathname === "/About/" ||
    pathname === "/Join/" ||
    pathname === "/Login/" ||
    pathname === "/ContactUs/" ||
    pathname === "/UploadDrawing/" ||
    pathname === "/Welcome/"
  );


  const redirectToLogin = () => {
    if (
      isGreetingPage ||
      pathname.includes("Shared") ||
      window.location.href.toLowerCase().includes("login") ||
      window.location.hostname.toLowerCase().startsWith("doxle") ||
      window.location.hostname.toLowerCase().startsWith("www") ||
      (!window.location.hostname.toLowerCase().includes("doxle") &&
        pathname === "/")
    ) return;
    window.open("https://login.doxle.com/?app=bills", "_self");
  };

  useEffect(() => {
    // INTERCEPT ALL AXIOS RESPONSES
    const successInterceptor = (response: AxiosResponse<any, any>) => response;

    // INTERCEPT ALL AXIOS ERRORS
    const errInterceptor = (error: AxiosError<any>) => {
      if (
        error?.response?.status === 403 &&
        error?.response?.data?.detail?.includes("VerifyUserException")
      ) {
        setLoggedIn(false);
        redirectToLogin();
      }
      else showNotification(
        `${error?.response?.status ?? "ERROR"}: ${
          error?.response?.statusText ?? "Unkown Error"
        }`,
        "error",
        String(error?.response?.data).substring(0, 250) ?? "Error Processing Request"
      );
      return Promise.reject(error);
    };
    BillsAPI.interceptors.response.clear();
    BillsAPI.interceptors.response.use(successInterceptor, errInterceptor);
  }, [navigate, companyId]);

  return children;
};
