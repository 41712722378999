import * as React from "react";
import { Popover } from "@mui/material";
import { PopoverTable } from "./PopoverTable";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  DoxleTopMenuIcon,
} from "../DoxleTopMenuIcon";
import DoxleSideMenu from "../../DoxleSideMenu/DoxleSideMenu";
import { useDoxleMainMenuStore } from "../../../DoxleGeneralStore/useDoxleMainMenuStore";

export interface INavBarProps {}

export default function NavBarEllipsis(props: INavBarProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const themeColor = useDoxleThemeStore(
    (state) => state.doxleThemeColor,
    shallow
  );
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    //setAnchorEl(event.currentTarget);
    setShowSideMenu(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const { showSideMenu, setShowSideMenu } = useDoxleMainMenuStore(
    (state) => ({
      showSideMenu: state.showSideMenu,
      setShowSideMenu: state.setShowSideMenu,
    }),
    shallow
  );

  const { doxleThemeColor, doxleFont, currentTheme, setTheme } =
    useDoxleThemeStore(
      (state) => ({
        doxleThemeColor: state.doxleThemeColor,
        doxleFont: state.doxleFont,
        currentTheme: state.currentTheme,
        setTheme: state.setTheme,
      }),
      shallow
    );

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  return (
    <>
      <DoxleSideMenu />
      <button
        style={{
          all: "initial",
          cursor: "pointer",
          marginRight: 30,
          marginTop: 5,
        }}
        onClick={handleClick}
      >
        {/* <NavBarEllipsisIcon {...themeColor}/> */}
        {/* <ToggleMenuIcon themeColor={doxleThemeColor} /> */}
        <DoxleTopMenuIcon
          containerStyle={{
            marginLeft: 2,
          }}
        />
      </button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <PopoverTable handleClose={handleClose} />
      </Popover>
    </>
  );
}
