import * as React from "react";
import {
  StyledCompanyContent,
  StyledDoxleLogoContainer,
  StyledPopoverTableContainer,
  StyledPopoverTableContent,
  StyledPopoverTableFooter,
  StyledPopoverTableLoginRecord,
  StyledPopoverTableMiddleContent,
} from "./StyledNavBarEllipsis";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { useNavigate } from "react-router-dom";
import { useDoxleAuthStore } from "../../../DoxleGeneralStore/useDoxleAuthStore";
import { shallow } from "zustand/shallow";
import {
  StyledItemIconAndLabelSection,
  StyledSelectCompanyHeaderSection,
} from "../StyledComponentDoxleSideMenu";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { MouseEvent, useState } from "react";
import { Company } from "../../../Models/company";
import doxleLogo from "./Asset/doxleLogo.svg";
import {
  MenuTransparentTiles,
  SideMenuDropDownIcon,
  StyledButtonBarIcon,
  StyledFileIcon,
  StyledFontIcon,
  StyledInviteIcon,
  StyledReportIcon,
  StyledSettingsIcon,
  StyledStoryIcon,
  StyledThemeIcon,
  StyledXeroIcon,
} from "./Asset/SideMenuIcons";
import { useDoxleMainMenuStore } from "../../../DoxleGeneralStore/useDoxleMainMenuStore";
import {
  DoxleBWIcon,
  DoxleNBIcon,
  DoxleProjectIcon,
} from "../../../DoxleDesignPattern/DoxleGeneralIcons";
import { IoIosAddCircleOutline } from "react-icons/io";
import CompanyListItem from "./CompanyListItem";
import dayjs from "dayjs";
import { SelectFontTab } from "./SelectFontTab";
import useGetCompanyList from "../../../Services/QueryHooks/useGetCompanyList";
import CompanyOptionsDropdown, {CompanyDropDownProps} from "../../DoxleSideMenu/DoxleCompanySelection/CompanyOptionsDropdown";

export function SideMenuTable() {
  const [displayCompanies, setDisplayCompanies] = useState(false);
  const [displayChangeFontTab, setDisplayChangeFontTab] = useState(false);
  const [companyDropDownProps, setCompanyDropDownProps] = useState<null | Omit<
    CompanyDropDownProps,
    "handleClose"
  >>(null);
  const navigate = useNavigate();
  const { setShowSideMenu } = useDoxleMainMenuStore(
    (state) => ({
      setShowSideMenu: state.setShowSideMenu,
    }),
    shallow
  );
  const {
    currentCompany,
    setCurrentCompany,
    setCureentEditCompany,
    currentMenuPosition,
    setCurrentMenuPosition,
  } = useDoxleCurrentContextStore(
    (state) => ({
      currentCompany: state.currentCompany,
      setCurrentCompany: state.setCurrentCompany,
      currentEditCompany: state.setCurrentEditCompany,
      setCureentEditCompany: state.setCurrentEditCompany,
      currentMenuPosition: state.currentMenuPosition,
      setCurrentMenuPosition: state.setCurrentMenuPosition,
    }),
    shallow
  );
  const handleSelectCompany = (e: MouseEvent, selectedCompany?: Company) => {
    e.stopPropagation();
    e.preventDefault();
    setCurrentCompany(selectedCompany);
    setCureentEditCompany(selectedCompany);
    if (selectedCompany) {
      // navigate("/Projects/");
    } else navigate("/CompanySettings/");
    setShowSideMenu(false);
  };
  const { user, logOut } = useDoxleAuthStore(
    (state) => ({
      user: state.user,
      logOut: state.handleLogOut,
    }),
    shallow
  );
  const {
    $fontMode,
    setFontMode,
    transparentMode,
    setTransparentMode,
    ...styleProps
  } = useDoxleThemeStore(
    (state) => ({
      $themeColor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
      $currentTheme: state.currentTheme,
      $fontMode: state.fontMode,
      setFontMode: state.setFontMode,
      transparentMode: state.transparentMode,
      setTransparentMode: state.setTransparentMode,
    }),
    shallow
  );
  const { companyList } = useGetCompanyList({});
  const setTheme = useDoxleThemeStore((state) => state.setTheme);

  const themeTitle =
    styleProps.$currentTheme[0].toUpperCase() +
    styleProps.$currentTheme.substring(1);

  const handleChangeTheme = () => {
    setTheme(
      styleProps.$currentTheme === "light"
        ? "contrast"
        : styleProps.$currentTheme === "contrast"
        ? "dark"
        : "light"
    );
  };
  interface MiddleContent {
    text: string;
    icon?: string;
    disabled?: true;
    hasLine?: true;
  }
  const str = currentMenuPosition === "Top" ? "Bottom" : "Top";
  const middleContent: MiddleContent[] = [
    { text: themeTitle + " Theme", disabled: true },
    { text: "Font Styles", disabled: true },
    { text: "Switch to " + str + " Bar", disabled: true },
    { text: "Tile Background", disabled: true, hasLine: true },
    { text: "Projects", disabled: true },
    { text: "Notice Board", disabled: true },
    { text: "Files", disabled: true },
    { text: "Settings", disabled: true },
    { text: "Reports", disabled: true, hasLine: true },
    { text: "Inventory", disabled: true, hasLine: true },
    { text: "Our Story", disabled: true },
    { text: "Invite to Doxle", disabled: true, hasLine: true },
  ];

  const getSideMenuOptionIcon = (option: string) => {
    if (option.includes("Theme")) {
      return <StyledThemeIcon themeColor={styleProps.$themeColor} />;
    } else if (option === "Font Styles") {
      return <StyledFontIcon themeColor={styleProps.$themeColor} />;
    } else if (option.includes("Switch to")) {
      return <StyledButtonBarIcon themeColor={styleProps.$themeColor} />;
    } else if (option === "Files") {
      return <StyledFileIcon themeColor={styleProps.$themeColor} />;
    } else if (option === "Xero Settings") {
      return <StyledXeroIcon themeColor={styleProps.$themeColor} />;
    } else if (option === "Reports") {
      return <StyledReportIcon themeColor={styleProps.$themeColor} />;
    } else if (option === "Our Story") {
      return <StyledStoryIcon themeColor={styleProps.$themeColor} />;
    } else if (option === "Invite to Doxle") {
      return <StyledInviteIcon themeColor={styleProps.$themeColor} />;
    } else if (option === "Tile Background") {
      return <MenuTransparentTiles themeColor={styleProps.$themeColor} />;
    } else if (option === "Inventory") {
      return <StyledStoryIcon themeColor={styleProps.$themeColor} />;
    } else if (option === "Settings") {
      return <StyledSettingsIcon themeColor={styleProps.$themeColor} />;
    } else if (option === "Projects") {
      return <DoxleProjectIcon themeColor={styleProps.$themeColor} />;
    } else if (option === "Notice Board") {
      return (
        <DoxleNBIcon
          themeColor={styleProps.$themeColor}
          containerStyle={{ width: 16, marginRight: 4 }}
        />
      );
    }
  };

  const handleItemClick = (item: MiddleContent) => {
    if (item.text.includes("Theme")) {
      handleChangeTheme();
      return;
    }

    if (item.text.includes("Font Styles")) {
      // if ($fontMode === "Default") setFontMode("Inter");
      // else if ($fontMode === "Inter") setFontMode("Mono");
      // else if ($fontMode === "Mono") setFontMode("Serif");
      // else setFontMode("Default");
      setDisplayChangeFontTab(true);
      return;
    }

    // if (item.text === "Xero Settings") {
    //   navigate("/Xero/");
    // }
    if (item.text === "Our Story") {
      navigate("/OurStory/");
    }

    if (item.text.includes("Files")) {
      navigate("/Files/");
    }

    if (item.text.includes("Inventory")) {
      if (window.location.host.includes("doxle.com"))
        window.open("https://inventory.doxle.com/");
      else navigate("/Inventory/");
    }



    if (item.text.includes("Switch to")) {
      if (currentMenuPosition === "Top") {
        setCurrentMenuPosition("Bottom");
      } else {
        setCurrentMenuPosition("Top");
      }
    }

    if (item.text.includes("Tile Background")) {
      setTransparentMode(!transparentMode);
    }

    if (item.text === "Settings") {
      navigate("/Settings/");
    }

    if (item.text === "Projects") {
      navigate("/Projects/");
      if (window.location.host.includes("doxle.com"))
        window.open("https://projects.doxle.com/");
      else navigate("/Projects/");
    }

    if (item.text === "Notice Board") {
      if (window.location.host.includes("doxle.com"))
        window.open("https://noticeboard.doxle.com/");
      else navigate("/Noticeboard/");
    }
    setShowSideMenu(false);
  };

  const handleCompanyKebabIcon = (
    e: React.MouseEvent<SVGSVGElement>,
    company: Company
  ) => {
    e.stopPropagation();
    e.preventDefault();
    setCompanyDropDownProps({
      company,
      anchorEl: e.currentTarget,
    });
  };

  const handleCloseCompanyOptions = () => {
    setCompanyDropDownProps(null);
  };

  return (
    <StyledPopoverTableContainer {...styleProps}>
      <StyledPopoverTableContent {...styleProps}>
        {!displayCompanies && !displayChangeFontTab && (
          <>
            <StyledCompanyContent {...styleProps}>
              <StyledSelectCompanyHeaderSection
                {...styleProps}
              ></StyledSelectCompanyHeaderSection>
              <StyledItemIconAndLabelSection
                {...styleProps}
                onClick={() => setDisplayCompanies((prev) => !prev)}
              >
                {currentCompany ? (
                  <>
                    {currentCompany.logo ? (
                      <img src={currentCompany?.logo} height={20} />
                    ) : (
                      <DoxleBWIcon
                        themeColor={styleProps.$themeColor}
                        containerStyle={{
                          height: 20,
                        }}
                        scaleDirection="height"
                      />
                    )}
                    &nbsp;&nbsp;
                    <span>{currentCompany?.name ?? "Select a Company"}</span>
                  </>
                ) : (
                  "Unknown Company"
                )}

                <SideMenuDropDownIcon themeColor={styleProps.$themeColor} />
              </StyledItemIconAndLabelSection>
            </StyledCompanyContent>
            <StyledPopoverTableMiddleContent {...styleProps}>
              <ul>
                {middleContent.map((item, index) => (
                  <li key={index} onClick={() => handleItemClick(item)}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {getSideMenuOptionIcon(item.text)}
                      {<span>{item.text}</span>}
                    </div>
                    {item.hasLine && <hr style={{ marginTop: "1.5rem" }} />}
                  </li>
                ))}
              </ul>
            </StyledPopoverTableMiddleContent>
          </>
        )}

        {displayChangeFontTab && (
          <SelectFontTab setDisplayChangeFontTab={setDisplayChangeFontTab}/>
        )}

        {displayCompanies && (
          <StyledCompanyContent {...styleProps}>
            <StyledSelectCompanyHeaderSection {...styleProps}>
              <span>Select a Company</span>
            </StyledSelectCompanyHeaderSection>
            {companyList.map((company: Company) => (
              <CompanyListItem
                company={company}
                handleCompanyKebabIcon={handleCompanyKebabIcon}
                handleSelectCompany={handleSelectCompany}
                key={company.companyId}
              />
            ))}
            <StyledItemIconAndLabelSection
              {...styleProps}
              $disableBackground
              onClick={(e: MouseEvent) => handleSelectCompany(e, undefined)}
            >
              <IoIosAddCircleOutline
                size={20}
                color={styleProps.$themeColor.primaryFontColor}
              />
              &nbsp;&nbsp;
              <span>Add a newCompany</span>
            </StyledItemIconAndLabelSection>
            <hr style={{ marginTop: "1.5rem" }} />
          </StyledCompanyContent>
        )}

        <StyledPopoverTableFooter {...styleProps}>
          <span
            style={{ cursor: "pointer" }}
            className="signOut"
            onClick={() => logOut()}
          >
            Sign out
          </span>
          <span className="email">{user?.email}</span>
          <StyledPopoverTableLoginRecord {...styleProps}>
            {user?.lastLogin
              ? `Last login ${dayjs(user?.lastLogin).format(
                  "MMMM DD, YYYY HH:mm A"
                )}`
              : `-`}
          </StyledPopoverTableLoginRecord>
          <StyledDoxleLogoContainer>
            <img src={doxleLogo} style={{ maxWidth: 170 }} />
          </StyledDoxleLogoContainer>
        </StyledPopoverTableFooter>
      </StyledPopoverTableContent>
      {companyDropDownProps && (
        <CompanyOptionsDropdown
          {...companyDropDownProps}
          handleClose={handleCloseCompanyOptions}
        />
      )}
    </StyledPopoverTableContainer>
  );
}
