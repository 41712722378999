import { useDoxleThemeStore } from "../DoxleGeneralStore/useDoxleThemeStore";
import { StyledSendIcon } from "../Bills/BillIcons";
import { StyledBannerContainer } from "../Bills/Styles/StyledBills";
import {
  StyledBottomContainer,
  StyledBottomLeftContainer,
  StyledBottomRightContainer,
  StyledButtonContainer,
  StyledEdittableInput,
  StyledGreyTitle,
  StyledInvoiceDetailTable,
  StyledInvoiceRootContainer,
  StyledInvoiceSummaryContainer,
  StyledInvoiceTemplateContainer,
  StyledMiddleContainer,
  StyledOriginalInvoiceContainer,
  StyledSendBtn,
  StyledSummaryRow,
  StyledTextNote,
  StyledTopContainer,
  StyledTopLeftContainer,
  StyledTopRightContainer,
  StyledWrapper,
} from "./StyledProcessedInvoice";
import Loading from "../Utilities/Lottie/Loading";
import {formatter, toNum} from "../Utilities/FunctionUtilities";
import useBill from "./Hooks/useBill";
import ProcessedBillLine from "./BillLine";
export function ProcessedInvoice() {

  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));

  const {
    billDetailQuery,
    bill,
    formatABN,
    handleChange,
    handleKeyDown,
    handleNewLineKeyDown,
    handleBlur,
    handleSendToXero
  } = useBill()

  if (billDetailQuery.isLoading) return <Loading/>
  if (billDetailQuery.isError || bill === undefined) return <>Error - Unable to get bill</>
  return (
    <StyledInvoiceRootContainer>
        <StyledWrapper>
          <StyledOriginalInvoiceContainer $themeColor={doxleThemeColor}>
            {bill?.thumbnail && <img src={bill.thumbnail.replaceAll(" ", "%20")} style={{maxWidth: '100%'}}/>}
          </StyledOriginalInvoiceContainer>
          <StyledTextNote $themeColor={doxleThemeColor} $doxleFont={doxleFont}>
            Original Invoice
          </StyledTextNote>
        </StyledWrapper>

        <StyledWrapper>
          <StyledInvoiceTemplateContainer $themeColor={doxleThemeColor}>
            <StyledButtonContainer>
            <StyledSendBtn $themeColor={doxleThemeColor} onClick={handleSendToXero}>
              <StyledSendIcon themeColor={doxleThemeColor} />
              <span style={{ marginLeft: 5 }}>Send</span>
            </StyledSendBtn>
            </StyledButtonContainer>

            <StyledTopContainer>
              <StyledTopLeftContainer>
                <StyledEdittableInput
                  $themeColor={doxleThemeColor}
                  $fontSize={1.6}
                  $fontWeight={700}
                  value={bill.supplierName ?? ""}
                  onChange={(e) => handleChange('supplierName', e)}
                  onKeyDown={(e) => handleKeyDown('supplierName', e)}
                  onBlur={(e) => handleBlur('supplierName', e)}
                />
                <StyledGreyTitle $themeColor={doxleThemeColor}>
                  ABN:
                  <StyledEdittableInput
                    $themeColor={doxleThemeColor}
                    value={formatABN}
                    onChange={(e) => handleChange('supplierAbn', e)}
                    onKeyDown={(e) => handleKeyDown('supplierAbn', e)}
                    onBlur={(e) => handleBlur('supplierAbn', e)}
                  />
                </StyledGreyTitle>
                <div style={{ marginBottom: "2rem" }}></div>
                <StyledGreyTitle $themeColor={doxleThemeColor}>
                  Invoice Number:
                </StyledGreyTitle>
                <StyledEdittableInput
                  $themeColor={doxleThemeColor}
                  value={bill?.invoiceNumber ?? ""}
                  onChange={(e) => handleChange('invoiceNumber', e)}
                  onKeyDown={(e) => handleKeyDown('invoiceNumber', e)}
                  onBlur={(e) => handleBlur('invoiceNumber', e)}
                />
                <div style={{ marginBottom: "1rem" }}></div>
                <StyledGreyTitle $themeColor={doxleThemeColor}>
                  Date:
                </StyledGreyTitle>
                <StyledEdittableInput
                  $themeColor={doxleThemeColor}
                  value={bill?.issueDate ? new Date(bill.issueDate).toDateString() : new Date().toDateString()}
                  onChange={(e) => handleChange('issueDate', e)}
                  onKeyDown={(e) => handleKeyDown('issueDate', e)}
                  onBlur={(e) => handleBlur('issueDate', e)}
                />
                <div style={{ marginBottom: "2rem" }}></div>
              </StyledTopLeftContainer>
              <StyledTopRightContainer $themeColor={doxleThemeColor}>
                INVOICE
              </StyledTopRightContainer>
            </StyledTopContainer>

            <StyledMiddleContainer>
              <StyledInvoiceDetailTable $themeColor={doxleThemeColor}>
                <tr>
                  <th>Description</th>
                  <th>Qty</th>
                  <th>Rate</th>
                  <th>Price</th>
                  <th/>
                </tr>
                {bill.lines?.map(line => <ProcessedBillLine initLine={line} /> )}
                <tr>
                  <td>
                    <StyledEdittableInput
                      $themeColor={doxleThemeColor}
                      defaultValue="New Line"
                      placeholder={"New Line"}
                      onKeyDown={handleNewLineKeyDown}
                    />
                  </td>
                </tr>
              </StyledInvoiceDetailTable>

              <StyledInvoiceSummaryContainer>
                <div style={{ marginTop: "1rem", marginBottom: "1rem" }} />
                <StyledSummaryRow>
                  <StyledGreyTitle $themeColor={doxleThemeColor}>
                    Subtotal
                  </StyledGreyTitle>
                  <StyledEdittableInput
                    $width={"3.7rem"}
                    $themeColor={doxleThemeColor}
                    // value={formatter.format(toNum(bill?.billSubtotal))}
                    value={bill?.billSubtotal ?? ""}
                    // onChange={(e) => handleChange('billSubtotal', e)}
                    // onKeyDown={(e) => handleKeyDown('billSubtotal', e)}
                    // onBlur={(e) => handleBlur('billSubtotal', e)}
                  />
                </StyledSummaryRow>

                <div style={{ marginTop: "1rem", marginBottom: "1rem" }} />

                <StyledSummaryRow>
                  <StyledGreyTitle $themeColor={doxleThemeColor}>
                    GST (10%)
                  </StyledGreyTitle>
                  <StyledEdittableInput
                    $width={"3.7rem"}
                    $themeColor={doxleThemeColor}
                    // value={formatter.format(toNum(bill?.billTax))}
                    value={bill?.billTax ?? ""}
                    // onChange={(e) => handleChange('billTax', e)}
                    // onKeyDown={(e) => handleKeyDown('billTax', e)}
                    // onBlur={(e) => handleBlur('billTax', e)}
                  />
                </StyledSummaryRow>

                <div
                  style={{
                    border: "1px solid #F2F2F2",
                    marginTop: "1rem",
                    marginBottom: "1rem",
                  }}
                />

                <StyledSummaryRow>
                  <StyledGreyTitle
                    $themeColor={doxleThemeColor}
                    style={{ fontWeight: 500 }}
                  >
                    Total
                  </StyledGreyTitle>
                  <StyledEdittableInput
                    $width={"3.7rem"}
                    $themeColor={doxleThemeColor}
                    // value={formatter.format(toNum(bill?.billTotal))}
                    value={bill?.billTotal ?? ""}
                    onChange={(e) => handleChange('billTotal', e)}
                    onKeyDown={(e) => handleKeyDown('billTotal', e)}
                    onBlur={(e) => handleBlur('billTotal', e)}
                  />
                </StyledSummaryRow>

                <div
                  style={{
                    border: "1px solid #F2F2F2",
                    marginTop: "1rem",
                    marginBottom: "1rem",
                  }}
                />
              </StyledInvoiceSummaryContainer>
            </StyledMiddleContainer>

            <StyledBottomContainer>
              <StyledBottomLeftContainer>
                <StyledSummaryRow>
                  <StyledGreyTitle $themeColor={doxleThemeColor}>
                    Bank:
                  </StyledGreyTitle>
                  <StyledEdittableInput
                    $themeColor={doxleThemeColor}
                    $width={"80%"}
                    value={bill?.supplierBankName ?? ""}
                    onChange={(e) => handleChange('supplierBankName', e)}
                    onKeyDown={(e) => handleKeyDown('supplierBankName', e)}
                    onBlur={(e) => handleBlur('supplierBankName', e)}
                  />
                </StyledSummaryRow>

                <StyledSummaryRow>
                  <StyledGreyTitle $themeColor={doxleThemeColor}>
                    BSB:
                  </StyledGreyTitle>
                  <StyledEdittableInput
                    $themeColor={doxleThemeColor}
                    $width={"80%"}
                    value={bill?.supplierBsb ?? ""}
                    onChange={(e) => handleChange('supplierBsb', e)}
                    onKeyDown={(e) => handleKeyDown('supplierBsb', e)}
                    onBlur={(e) => handleBlur('supplierBsb', e)}
                    disabled
                  />
                </StyledSummaryRow>

                <StyledSummaryRow>
                  <StyledGreyTitle $themeColor={doxleThemeColor}>
                    ACC:
                  </StyledGreyTitle>
                  <StyledEdittableInput
                    $themeColor={doxleThemeColor}
                    $width={"80%"}
                    value={bill?.supplierBankAccount ?? ""}
                    onChange={(e) => handleChange('supplierBankAccount', e)}
                    onKeyDown={(e) => handleKeyDown('supplierBankAccount', e)}
                    onBlur={(e) => handleBlur('supplierBankAccount', e)}
                    disabled
                  />
                </StyledSummaryRow>
              </StyledBottomLeftContainer>

              <StyledBottomRightContainer $themeColor={doxleThemeColor}>
                <StyledSummaryRow>
                  <StyledGreyTitle
                    $themeColor={doxleThemeColor}
                    style={{
                      color: "rgba(255, 255, 255, 0.60)",
                      position: "relative",
                      top: 4,
                    }}
                  >
                    DUE:
                  </StyledGreyTitle>
                  <StyledEdittableInput
                    $themeColor={doxleThemeColor}
                    value={formatter.format(toNum(bill?.billDueAmount))}
                    onKeyDown={(e) => handleKeyDown('billDueAmount', e)}
                    onBlur={(e) => handleBlur('billDueAmount', e)}
                    $width={"60%"}
                    style={{
                      fontSize: "1.6rem",
                      fontWeight: "600",
                      color: "white",
                    }}
                  />
                </StyledSummaryRow>
              </StyledBottomRightContainer>
            </StyledBottomContainer>
          </StyledInvoiceTemplateContainer>

          <StyledTextNote $themeColor={doxleThemeColor} $doxleFont={doxleFont}>
            Invoice generated by Doxle
          </StyledTextNote>
        </StyledWrapper>
    </StyledInvoiceRootContainer>
  );
}
