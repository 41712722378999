import { create } from "zustand";
import { Company } from "../Models/company";
import { Project } from "../Models/project";

interface DoxleCurrentContextStore {
  currentProject: Project | undefined;
  setCurrentProject: (project: Project | undefined) => void;

  currentSearchText: string;
  setCurrentSearchText: (value: string) => void;

  currentCompany: Company | undefined;
  setCurrentCompany: (company: Company | undefined) => void;

  isLoadingProjectDetail: boolean;
  setIsLoadingProjectDetail: (value: boolean) => void;

  currentEditCompany: Company | undefined;
  setCurrentEditCompany: (company: Company | undefined) => void;

  currentMenuPosition: "Top" | "Bottom";
  setCurrentMenuPosition: (position: "Top" | "Bottom") => void;

  initializeMenuPosition: () => void;

}

export const useDoxleCurrentContextStore = create<DoxleCurrentContextStore>(
  (set, get) => ({
    currentCompany: undefined,
    setCurrentCompany: (company: Company | undefined) => {
      set((state) => ({
        currentCompany: company,
      }));

      //!STORE TO LOCAL
      if (company) {
        localStorage.setItem("currentCompanyId", company.companyId);
        set((state) => ({
          currentProject: undefined,
        }));
      }

      // else localStorage.removeItem("currentCompanyId");
    },

    currentProject: undefined,
    setCurrentProject: (project: Project | undefined) => {
      set((state) => ({ currentProject: project }));
      if (project) {
        localStorage.setItem("currentProjectId", project.projectId);
      } else localStorage.removeItem("currentProjectId");
    },

    currentSearchText: "",
    setCurrentSearchText: (value: string) =>
      set((state) => ({ currentSearchText: value })),

    isLoadingProjectDetail: false,
    setIsLoadingProjectDetail: (value: boolean) =>
      set({ isLoadingProjectDetail: value }),

    currentEditCompany:  undefined,
    setCurrentEditCompany: (company: Company | undefined) => {
      set((state) => ({
        currentEditCompany: company,
      }));
    },

    currentMenuPosition: "Top",
    setCurrentMenuPosition(position: "Top" | "Bottom"){
      set((state) => ({
        currentMenuPosition: position,
      }));
      localStorage.setItem("lastMenuPosition", position);
    },

    initializeMenuPosition: () => {
      const lastMenuPosistionStorage = localStorage.getItem("lastMenuPosition");
      if (lastMenuPosistionStorage) get().setCurrentMenuPosition(lastMenuPosistionStorage as "Top" | "Bottom");
    }, 

  })
);
