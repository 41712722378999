import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../DoxleGeneralStore/useDoxleThemeStore";
import { editRgbaAlpha } from "../Utilities/FunctionUtilities";
import { motion } from "framer-motion";

export const StyledInvoiceRootContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
height: 100%;
`;

export const StyledWrapper = styled.div`
margin-left: 5rem;
margin-right: 5rem;
`;

export const StyledInvoiceTemplateContainer = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  width: 42.9rem;
  height: 56.2rem;
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  position: relative;
`;


export const StyledButtonContainer = styled.div`
  position: absolute;
  right: 0;
  top: -7rem;
`;

export const StyledOriginalInvoiceContainer = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  max-width: 42.9rem;
  max-height: 56.2rem;
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;



export const StyledTopContainer = styled.div`
display: flex;
padding: 2rem;
`;

export const StyledTopLeftContainer = styled.div`
width: 70%;
`;

export const StyledMiddleContainer = styled.div`

`;

export const StyledBottomContainer = styled.div`
position: absolute;
bottom: 3rem;
display: flex;
width: 100%;
`;

export const StyledBottomLeftContainer = styled.div`
background-color: #F2F2F2;
padding: 1rem;
width: 60%;
`;
export const StyledBottomRightContainer = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
display: flex;
align-items: center;
width: 40%;
background-color: ${(p) => p.$themeColor.doxleColor};
`;
export const StyledTopRightContainer = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
display: flex;
align-items: center;
justify-content: center;
font-size: 2.6rem;
font-weight: 700;
color: ${(p) => p.$themeColor.primaryFontColor};
`;



export const StyledGreyTitle = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
color: ${(p) => editRgbaAlpha({rgbaColor: p.$themeColor.primaryFontColor, alpha:"0.6"})};
font-size: 1rem;
display: flex;
`;

export const StyledEdittableInput = styled.input<{
  $themeColor: DoxleThemeColor;
  $fontSize?: number;
  $fontWeight?: number;
  $width?:string;
}>`
font-size: ${(p) => p.$fontSize?p.$fontSize+"rem":"1rem"};
font-weight: ${(p) => p.$fontWeight?p.$fontWeight:"nomral"};
background-color: transparent;
border: none;
color: ${(p) => p.$themeColor.primaryFontColor};
width: ${(p) => p.$width?p.$width:"100%"};
max-width: 20rem;
border: 1px solid rgba(0,0,0,0);


&:focus {
  outline: none;
  border: 1px solid ${(p) => p.$themeColor.doxleColor};

}

`

export const StyledInvoiceDetailTable = styled.table<{
  $themeColor: DoxleThemeColor;
}>`
width: 100%;
border-collapse: collapse;
th {
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: ${(p) => p.$themeColor.primaryFontColor};
  color:  ${(p) => p.$themeColor.primaryReverseFontColor};
}

td {
  border-bottom: 1px solid #F2F2F2;
  padding: 1rem;
}

tr th:nth-child(1){
  width: 50%;
}
.icon-cell{
  width: 10px;
  padding: 1rem 0;
  & svg {
    cursor: pointer;
  }
}
`

export const StyledInvoiceSummaryContainer = styled.div`
position: absolute;
width: 30%;
right: 0;
`;

export const StyledSummaryRow = styled.div`
display: flex;
justify-content:space-around;
`;

export const StyledTextNote = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
color:${(p) => editRgbaAlpha({rgbaColor: p.$themeColor.primaryFontColor, alpha:"0.4"})};
font-size: 0.9rem;
font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
width: 100%;
text-align: center;
margin-top: 1rem;
`

export const StyledSendBtn = styled.button<{
  $themeColor: DoxleThemeColor;
}>`
  background-color: ${(p) => p.$themeColor.primaryFontColor};
  color: ${(p) => p.$themeColor.primaryBackgroundColor};
  width: 8.3rem;
  height: 3.2rem;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  margin-top: 1rem;
  font-size: 1.4rem;
  border-radius: 1.5rem;
`;
