import { SVGProps } from "react";
import { DoxleThemeColor } from "../../DoxleGeneralStore/useDoxleThemeStore";

export const NotiSuccessIcon = ({
  themeColor,
  containerStyle,
  ...props
}: SVGProps<SVGSVGElement> & {
  themeColor: DoxleThemeColor;
  containerStyle?: React.CSSProperties;
}) => (
  <div
    style={{
      width: 29,
      ...containerStyle,
      display: "flex",
      aspectRatio: 1,
    }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 29 29"
      {...props}
    >
      <path
        fill={themeColor.primaryReverseFontColor}
        d="M14.5 0C22.508 0 29 6.492 29 14.5S22.508 29 14.5 29 0 22.508 0 14.5 6.492 0 14.5 0Zm6.26 9.872a.906.906 0 0 0-1.209-.066l-.073.066-7.334 7.334-2.622-2.622a.906.906 0 0 0-1.348 1.208l.066.074 3.263 3.262c.33.33.852.353 1.208.066l.074-.066 7.975-7.975a.906.906 0 0 0 0-1.281Z"
      />
    </svg>
  </div>
);

export const ErrorNotiIcon = ({
  themeColor,
  containerStyle,
  ...props
}: SVGProps<SVGSVGElement> & {
  themeColor: DoxleThemeColor;
  containerStyle?: React.CSSProperties;
}) => (
  <div
    style={{
      width: 29,
      ...containerStyle,
      display: "flex",
      aspectRatio: 1,
    }}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 48 48"
      fill="none"
      {...props}
    >
      <path
        fill={themeColor.primaryReverseFontColor}
        d="M24 4c11.046 0 20 8.954 20 20s-8.954 20-20 20S4 35.046 4 24 12.954 4 24 4Zm-6.116 12.116-.102-.091a1.25 1.25 0 0 0-1.564 0l-.102.091-.091.102a1.25 1.25 0 0 0 0 1.564l.091.102L22.233 24l-6.117 6.116-.091.102a1.25 1.25 0 0 0 0 1.564l.091.102.102.091a1.25 1.25 0 0 0 1.564 0l.102-.091L24 25.767l6.116 6.117.102.091a1.25 1.25 0 0 0 1.564 0l.102-.091.091-.102a1.25 1.25 0 0 0 0-1.564l-.091-.102L25.767 24l6.117-6.116.091-.102a1.25 1.25 0 0 0 0-1.564l-.091-.102-.102-.091a1.25 1.25 0 0 0-1.564 0l-.102.091L24 22.233l-6.116-6.117-.102-.091.102.091Z"
      />
    </svg>
  </div>
);
