import { Company } from "../../../Models/company";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import {
  StyledCompanyOptionsItem,
  StyledDropDownContainer,
  StyledOptions,
} from "./styledComponents";
import React, { useMemo, useState } from "react";
import { ClickAwayListener } from "@mui/material";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { useNavigate } from "react-router-dom";
// import ConfirmDeleteDialog from "../../AddCompanyScreen/DeleteCompanyDialog";
import CompanyQueryAPI from "../../../Services/QueryHooks/companyQueryAPI";
import { useDoxleMainMenuStore } from "../../../DoxleGeneralStore/useDoxleMainMenuStore";
import { DoxleDeleteIcon, DoxleEditIcon } from "../../../DoxleDesignPattern/DoxleGeneralIcons";
import useGetCompanyList from "../../../Services/QueryHooks/useGetCompanyList";

export interface CompanyDropDownProps {
  company: Company;
  anchorEl: SVGSVGElement;
  handleClose: () => void;
}
const CompanyOptionsDropdown = ({
  company,
  anchorEl,
  handleClose,
}: CompanyDropDownProps) => {
  const [displayDeleteDialog, setDisplayDeleteDialog] =
    useState<boolean>(false);
  const { currentCompany, setCurrentCompany, setCurrentEditCompany } = useDoxleCurrentContextStore(
    (state) => ({
      currentCompany: state.currentCompany,
      setCurrentCompany: state.setCurrentCompany,
      currentEditCompany: state.setCurrentEditCompany,
      setCurrentEditCompany: state.setCurrentEditCompany,
    }),
    shallow
  );
  const { setShowSideMenu } = useDoxleMainMenuStore(
    (state) => ({
      setShowSideMenu: state.setShowSideMenu,
    }),
    shallow
  );
  const styleProps = useDoxleThemeStore(
    (state) => ({
      $themeColor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
      $currentTheme: state.currentTheme,
    }),
    shallow
  );
  const navigate = useNavigate();
  const { companyList } = useGetCompanyList({});
  const onCompanyDeleted = () => {
    setDisplayDeleteDialog(false);
    setCurrentCompany(
      companyList.filter(
        (company) => company.companyId !== currentCompany?.companyId
      )?.[0]
    );
    navigate("/");
  };
  const deleteCompanyQuery = CompanyQueryAPI.useDeleteCompany(onCompanyDeleted);
  const handleDeleteConfirmed = (deleteCompanyId: string) => {
    deleteCompanyQuery.mutate(deleteCompanyId);
    handleClose();
  };
  interface IPosition {
    $top: number;
    $left: number;
  }
  const position: IPosition = useMemo(() => {
    const boundingRect = anchorEl.getBoundingClientRect();
    return {
      $top: boundingRect.top,
      $left: boundingRect.right,
    };
  }, [anchorEl]);

  const handleEditCompany = () => {
    // if (currentCompany?.companyId !== company.companyId)
    //   setCurrentCompany(company);
    setCurrentEditCompany(company);
    console.log(company.name);
    navigate("/CompanySettings/");
    handleClose();
    setShowSideMenu(false);
  };
  const handleDeleteCompany = () => {
    // setDisplayDeleteDialog(true);
  };
  // if (displayDeleteDialog)
  //   return (
  //     <ClickAwayListener onClickAway={handleClose}>
  //       <ConfirmDeleteDialog
  //         deleteType={"Company"}
  //         deleteItemName={company.name}
  //         deleteItemId={company.companyId}
  //         deleteFunction={handleDeleteConfirmed}
  //         closeDialog={() => setDisplayDeleteDialog(false)}
  //       />
  //     </ClickAwayListener>
  //   );
  // else
    return (
      <ClickAwayListener onClickAway={handleClose}>
        <StyledDropDownContainer {...styleProps} {...position}>
          <StyledCompanyOptionsItem {...styleProps} onClick={handleEditCompany}>
            <DoxleEditIcon themeColor={styleProps.$themeColor}/>
            <StyledOptions>Edit Company</StyledOptions>
          </StyledCompanyOptionsItem>
          <StyledCompanyOptionsItem
            {...styleProps}
            onClick={handleDeleteCompany}
          >
            <DoxleDeleteIcon themeColor={styleProps.$themeColor}/>
            <StyledOptions>Delete Company</StyledOptions>
          </StyledCompanyOptionsItem>
        </StyledDropDownContainer>
      </ClickAwayListener>
    );
};

export default CompanyOptionsDropdown;
