import React, { useCallback, useEffect, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { useDoxleThemeStore } from "../DoxleGeneralStore/useDoxleThemeStore";
import { useBillStore } from "./Store/useBillStore";
import {
  StyedButtonContainer,
  StyledBannerContainer,
  StyledMainUploadContainer,
  StyledSizeDescription,
  StyledSubmitBtn,
  StyledUploadContainer,
  StyledUploadFileContainer,
  StyledUploadPrompt,
  StyledUploadTitle,
} from "./Styles/StyledBills";
import { StyledFolderIcon, StyledUploadBanner } from "./BillIcons";
import { produce } from "immer";
import AddedFileItems from "./AddedFileItems";
import {useMutateBill} from "../Services/QueryHooks/useBillsQueries";
import {useNavigate} from "react-router-dom";

export const getFileExtension = (filename: string) => {
  return filename.split(".").pop();
};

export default function FileUploadPage() {
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));
  const [filename, setFilename] = useState("");
  const [files, setFiles] = useState<File[]>([]);
  const navigate = useNavigate()
  const uploadBillsQuery = useMutateBill({
    onSuccessCb: () => navigate('/')
  })
  const { setImageFiles, setPdfFiles, setStage } = useBillStore((state) => ({
    setStage: state.setCurrentStage,
    setPdfFiles: state.setPdfFiles,
    setImageFiles: state.setImageFiles,
  }));

  const onDrop = useCallback(
    <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[]) => {
      if (acceptedFiles) {
        setFiles((prev) =>
          produce(prev, (draft) => {
            draft = draft.concat(acceptedFiles);
            return draft;
          })
        );
      }
    },
    []
  );

  useEffect(() => {
    handleSubmitFile();
  }, [files]);

  const handleSubmitFile = () => {
    if (files.length > 0) uploadBillsQuery.uploadMultipleFile({files})
    // if (files.length > 0) {
    //   const pdfFiles = files.filter((f) => getFileExtension(f.name) === "pdf");
    //   const imageFiles = files.filter(
    //     (f) =>
    //       getFileExtension(f.name) === "png" ||
    //       getFileExtension(f.name) === "jpeg" ||
    //       getFileExtension(f.name) === "jpg"
    //   );
    //   setPdfFiles(pdfFiles);
    //   setImageFiles(imageFiles);
    //   setStage("preview");
    // }
  };

  const removeFile = useCallback((index: number) => {
    setFiles((prev) =>
      produce(prev, (draft) => {
        draft = draft.filter((_, idx) => idx !== index);
        return draft;
      })
    );
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [".pdf"],
      "image/jpeg": [".jpg"],
      "image/png": [".png"],
    },
  });

  return (
    <StyledMainUploadContainer>
      <StyledUploadContainer>
        <StyledBannerContainer {...getRootProps()}>
          <input {...getInputProps()} />
          <StyledUploadBanner themeColor={doxleThemeColor} />
        </StyledBannerContainer>

        <StyledUploadTitle $themeColor={doxleThemeColor} $doxleFont={doxleFont}>
          {filename ? filename : "Drag and drop your bills"}
        </StyledUploadTitle>
        <StyledUploadPrompt
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
        >
          You can drag and drop your emails with invoices, pdfs, jpeg and pngs.
          We will extract the text for you and upload to your accounting
          software of choice.
        </StyledUploadPrompt>

        <StyedButtonContainer>
          {files.length > 0 ? (
            <StyledSubmitBtn
              onClick={handleSubmitFile}
              $themeColor={doxleThemeColor}
            >
              Submit
            </StyledSubmitBtn>
          ) : (
            <div
              {...getRootProps()}
              style={{ cursor: "pointer", width: "fit-content" }}
            >
              <input {...getInputProps()} />
              <StyledSubmitBtn $themeColor={doxleThemeColor}>
                <StyledFolderIcon themeColor={doxleThemeColor} />
                <span style={{ marginLeft: 5 }}>Upload</span>
              </StyledSubmitBtn>
            </div>
          )}
        </StyedButtonContainer>

        <StyledUploadFileContainer>
          {files.map((file, index) => (
            <AddedFileItems
              file={file}
              index={index}
              removeFile={removeFile}
              key={`file${index}`}
            />
          ))}
        </StyledUploadFileContainer>
      </StyledUploadContainer>
    </StyledMainUploadContainer>
  );
}
