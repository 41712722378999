import {useParams} from "react-router-dom";
import {useMutateBill, useMutateBillLine, useRetrieveBillDetail} from "../../Services/QueryHooks/useBillsQueries";
import {useEffect, useMemo, useState} from "react";
import {Bill, blankBill} from "../../Models/bills";

const useBill = () => {
  const {billId} = useParams()
  const billDetailQuery = useRetrieveBillDetail(billId ?? "BillIdNotFound")
  const [bill, setBill] = useState<Bill>(billDetailQuery?.data?.data ?? blankBill)

  const mutateBillQuery = useMutateBill({});
  const mutateBillLineQuery = useMutateBillLine({billId: billId ?? ""});
  const formatABN = useMemo(() => {
    const abn = bill.supplierAbn ?? "";
    if (abn.length >= 8) return abn.substring(0,2)+" "+abn.substring(2,5)+" "+abn.substring(5,8)+" "+abn.substring(8)
    if (abn.length >= 5) return abn.substring(0,2)+" "+abn.substring(2,5)+" "+abn.substring(5)
    if (abn.length >= 2) return abn.substring(0,2)+" "+abn.substring(2)
    else return abn
  },[])
  const handleChange = (field: keyof Bill, e: React.ChangeEvent<HTMLInputElement>) => {
    setBill(prev => {
      if (
        field === "billId" || field === "lines" ||field === "pages" ||
        field === "numPages"  || field === "billSubtotal" || field === "billTax"
      ) return prev


      let value = e.target.value
      if (field === "supplierAbn") value = value.match(/\d/g)?.join('') ?? ''
      if ((field === "billTotal" || field === "billDueAmount")
        && isNumeric(value.replaceAll(',', '').replace('$', ''))
      )
        value = value.replaceAll(',', '').replace('$', '')
      let newBill: Bill = {...prev}
      newBill[field] = value
      return newBill
    })
  }
  const handleKeyDown = (field: keyof Bill, e: React.KeyboardEvent<HTMLInputElement>) => {
    console.log(e.key)
    if (
      e.key === "Escape" &&
      billDetailQuery?.data?.data?.[field] &&
      field !== "billId" && field !== "lines" && field !== "pages" && field !== "numPages"
    ) {
      setBill(prev => {
        let newBill: Bill = {...prev}
        newBill[field] = billDetailQuery?.data?.data?.[field] ?? ""
        return newBill
      })
    }
    if (e.key === "Enter") {
      let updateBill = bill;
      delete updateBill.lines
      delete updateBill.pages
      mutateBillQuery.update(updateBill)
    }
  }
  const handleNewLineKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") mutateBillLineQuery.create({billId: billId, description: e.currentTarget.value})
  }
  const handleBlur = (field: keyof Bill, e: React.FocusEvent<HTMLInputElement>) => {
    let updateBill = bill;
    delete updateBill.lines
    delete updateBill.pages
    mutateBillQuery.update(updateBill)
  }

  const handleSendToXero = () => {
    if (billId) mutateBillQuery.sendToXero(billId)
  }
  function isNumeric(value: string) {
    return /(^$)|(^-?\d+$)|(^-?\d+\.$)|(^-?\d+\.\d+$)/.test(value);
  }

  useEffect(()=>{
    if(billDetailQuery.data?.data) setBill(billDetailQuery.data.data)
  },[billDetailQuery.data?.data])

  return {
    billDetailQuery,
    bill,
    formatABN,
    handleChange,
    handleKeyDown,
    handleNewLineKeyDown,
    handleBlur,
    handleSendToXero
  }
}

export default useBill
