import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import { editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import { motion } from "framer-motion";

export const StyledUploadTitle = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-size: 3rem;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  margin-bottom: 0.5rem;
  color: ${(p) => p.$themeColor.primaryFontColor};
  max-width: 50rem;
  font-weight: 600;

  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const StyledUploadPrompt = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-size: 1.2rem;
  color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.primaryFontColor, alpha: "0.7" })};
  font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
  width: 384px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const StyledSizeDescription = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-size: 1.4rem;
  font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
  color: ${(p) => p.$themeColor.primaryFontColor};
  margin-top: 1rem;
`;

export const StyledMainUploadContainer = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  height: 100%;
`;

export const StyledUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyedButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const StyledBannerContainer = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
`;

export const StyledSubmitBtn = styled.button<{
  $themeColor: DoxleThemeColor;
}>`
  background-color: ${(p) => p.$themeColor.doxleColor};
  color: ${(p) => p.$themeColor.primaryBackgroundColor};
  font-size: 1.4rem;
  width: 10.2rem;
  height: 3.9rem;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  margin-top: 1rem;
`;

export const StyledSelectPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const StyledSelectPageInnerContainer = styled.div`
  max-width: 100rem;
  margin-top: 5rem;
  padding-left: 10rem;
  padding-right: 10rem;
`;

export const StyledSelectPageTitle = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  font-size: 1.4rem;
  border-bottom: 1px solid #dedeea;
  color: ${(p) => p.$themeColor.primaryFontColor};
  padding-bottom: 10px;
  margin-bottom: 4rem;
  width: 100%;
`;

export const StyledPdfPreviewContainer = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
`;

export const StyledPdfPreviewWrapper = styled.div`
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  max-height: 80vh;
  display: flex;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledSinglePageContainer = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  margin: 20px;
  /* border: 1px solid ${(p) => p.$themeColor.doxleColor}; */
  cursor: pointer;
  position: relative;
`;

export const StyledSelectedEffect = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  width: 479px;
  height: 100%;
  background-color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.doxleColor, alpha: "0.15" })};
  position: absolute;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const StyledSelectOrder = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(p) => p.$themeColor.doxleColor};
  font-weight: 600;
  font-size: 60px;
  border: 5px solid #ffffff;
  width: 61px;
  height: 61px;
`;

export const StyledProcessBtnContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-right: 50px;
`;

export const StyledProcessBtn = styled.button<{
  $themeColor: DoxleThemeColor;
}>`
  background-color: ${(p) => p.$themeColor.primaryFontColor};
  color: ${(p) => p.$themeColor.primaryBackgroundColor};
  width: 8.3rem;
  height: 3.2rem;
  border-radius: 3px;
  border: none;
  cursor: pointer;
  margin-top: 1rem;
  font-size: 1.4rem;
  border-radius: 1.5rem;
`;

export const StyledProcessingText = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-size: 1.4rem;
  color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.primaryFontColor, alpha: "0.6" })};
  font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const StyledFloorplanIconContainer = styled.div`
  margin-top: 8rem;
  margin-bottom: 5rem;
`;

export const StyledProcessPageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const StyledProcessPageInnerContainer = styled.div`
  max-width: 100rem;
  margin-top: 5rem;
  padding-left: 10rem;
  padding-right: 10rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const StyledUploadFileContainer = styled.div`
  width: 450px;
  margin-top: 2rem;
  overflow: auto;
  height: 75px;

  display: flex;
  flex-wrap: wrap;
  flex-shrink: 1;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledFileInfoContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100px;
  border: 1px solid #a9b0c2;
  margin: 0.4rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  position: relative;
  height: fit-content;
`;

export const StyledFileName = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-weight: 500;
  font-size: 1.2rem;
  color: ${(p) => p.$themeColor.primaryFontColor};
  font-family: ${(p) => p.$doxleFont.primaryFont};
  overflow-x: hidden;
  white-space: nowrap;
  margin-left: 0.5rem;
  max-width: 100%;
  text-overflow: ellipsis;
`;

export const StyledCloseButton = styled(motion.div)`
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
  z-index: 5;
`;
