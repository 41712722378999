import { useLottie } from "lottie-react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
// import animationData from "./lotties/doxle-loader.json"; // wagging tail
import animationData from "./lotties/Loading.json"; // running doggie
import { AnimatePresence, motion } from "framer-motion";
import React from "react";

interface props {
  text?: string;
  containerStyle?: React.CSSProperties;
  animationSize?: number;
  textStyle?: React.CSSProperties;
}

const Loading: React.FC<props> = ({
  text,
  containerStyle,
  animationSize = 250,
  textStyle,
}: props) => {
  const options = {
    animationData: animationData,
    loop: true,
    autoplay: true,
  };

  const style = {};

  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));

  const { View } = useLottie(options, { width: `${animationSize}px` });

  return (
    <AnimatePresence>
      <motion.div
        initial={{
          scale: 0,
          opacity: 0,
        }}
        animate={{
          scale: 1,
          opacity: 1,
        }}
        transition={{
          duration: 0.4,
        }}
        exit={{
          scale: 0,
          opacity: 0,
        }}
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "transparent",
          fontSize: "2.7rem",
          color: doxleThemeColor.bolderDividerColor,
          zIndex: 10,
          ...containerStyle,
        }}
      >
        {View} {text && <span style={{ ...textStyle }}>{text}</span>}
      </motion.div>
    </AnimatePresence>
  );
};

export default Loading;
