import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {useDoxleCurrentContextStore} from "../../DoxleGeneralStore/useDoxleCurrentContext";
import {shallow} from "zustand/shallow";
import {AddBillLine, Bill, PatchBill, PatchBillLine} from "../../Models/bills";
import {AxiosResponse} from "axios";
import BillsAPI from "../BillsAPI";

interface BillsParams {
  search?: string
}
export const useRetrieveBillsList = (params: BillsParams) => {
  const companyId = useDoxleCurrentContextStore(state=> state.currentCompany?.companyId, shallow)
  const qKey = ["bill-list", companyId];
  if (params.search) qKey.push(`search=${params.search}`)
  return useQuery(
    qKey,
    () =>
      BillsAPI.get<Bill[]>('', {
        headers: {'User-Company': companyId},
        params
      }),
    {
      enabled: Boolean(companyId),
      refetchInterval: 30000,
      retry: 1,
      staleTime: 0,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );
}

export const useRetrieveBillDetail = (billId: string) => {
  const companyId = useDoxleCurrentContextStore(state=> state.currentCompany?.companyId, shallow)
  const qKey = ["bill-detail", companyId, billId];
  return useQuery(
    qKey,
    () =>
      BillsAPI.get<Bill>(`${billId}/`, {
        headers: {'User-Company': companyId},
      }),
    {
      enabled: Boolean(companyId),
      // refetchInterval: 30000,
      // retry: 1,
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );
}



interface MutateBillProps {
  onSuccessCb?: () => void;
  onErrorCb?: () => void;
}
export const useMutateBill = ({onSuccessCb,onErrorCb}: MutateBillProps) => {
  const companyId = useDoxleCurrentContextStore(state=> state.currentCompany?.companyId, shallow)
  const queryClient = useQueryClient()

  interface UploadSingleFileProps {
    file: File
    pageData: number[][]
  }
  const uploadSingle = useMutation(
    ['upload-bill'],
    ({file, pageData}: UploadSingleFileProps) => {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('pageData', JSON.stringify(formData))
      return BillsAPI.post<Bill[]>(
        `Upload/`,
        formData,
        {headers: {'User-Company': companyId}}
      )
    },
    {
      onSuccess: (data, variables, context) => {
        if (onSuccessCb) onSuccessCb();
        queryClient.setQueryData<AxiosResponse<Bill[]>>(
          ["bill-list", companyId],
          (old)=> {
            if (!old) return old
            else return {
              ...old,
              data: [...data.data, ...old.data]
            }
          }
        );
      },
      onError: ()=> {
        if (onErrorCb) onErrorCb()
      }
    }
  );

  interface UploadMultipleFileProps {
    files: File[]
  }
  const uploadMultiple = useMutation(
    ['upload-bill'],
    ({files}: UploadMultipleFileProps) => {
      const formData = new FormData()
      files.forEach(file => formData.append('files', file))
      return BillsAPI.post<Bill[]>(
        `UploadMultiple/`,
        formData,
        {headers: {'User-Company': companyId}}
      )
    },
    {
      onSuccess: (data, variables, context) => {
        if (onSuccessCb) onSuccessCb();
        queryClient.setQueryData<AxiosResponse<Bill[]>>(
          ["bill-list", companyId],
          (old)=> {
            if (!old) return old
            else return {
              ...old,
              data: [...data.data, ...old.data]
            }
          }
        );
      },
      onError: ()=> {
        if (onErrorCb) onErrorCb()
      }
    }
  );


  const patch = useMutation(
    ['patch-bill'],
    (patchBill: PatchBill) =>
      BillsAPI.patch<Bill>(`${patchBill.billId}/`, patchBill, {
        headers: {'User-Company': companyId}
      }),
    {
      retry: 1,
      onSuccess: (data, variables, context) => {
        if (onSuccessCb) onSuccessCb()
        queryClient.setQueryData<AxiosResponse<Bill[]>>(
          ["bill-list", companyId],
          (old)=> {
          if (!old) return old
          else return {
            ...old,
            data: old.data.map(bill => bill.billId === data?.data?.billId ? data.data : bill)
          }
        })
        queryClient.setQueryData<AxiosResponse<Bill>>(
          ["bill-detail", companyId, variables.billId],
          (old)=> {
          if (!old) return old
          else return {
            ...old,
            data: data?.data?.billId ? data.data : old.data
          }
        })
      },
      onError: ()=> {
        if (onErrorCb) onErrorCb()
      }
    }
  );


  const sendToXero = useMutation(
    ['patch-bill'],
    (billId: string) =>
      BillsAPI.post<Bill>(`SendToXero/${billId}/`, {},{
        headers: {'User-Company': companyId}
      }),
    {
      retry: 1,
      onSuccess: (data, billId, context) => {
        if (onSuccessCb) onSuccessCb()
        queryClient.setQueryData<AxiosResponse<Bill[]>>(
          ["bill-list", companyId],
          (old)=> {
            if (!old) return old
            else return {
              ...old,
              data: old.data.map(bill => bill.billId === data?.data?.billId ? data.data : bill)
            }
          })
        queryClient.setQueryData<AxiosResponse<Bill>>(
          ["bill-detail", companyId, billId],
          (old)=> {
            if (!old) return old
            else return {
              ...old,
              data: data?.data?.billId ? data.data : old.data
            }
          })
      },
      onError: ()=> {
        if (onErrorCb) onErrorCb()
      }
    }
  );


  const deleteBill =  useMutation(
    ['delete-bill'],
    (billId: String) =>
      BillsAPI.delete(`${billId}/`, {
        headers: {'User-Company': companyId}
      }),
    {
      retry: 1,
      onSuccess: (data, billId, context) => {
        if (onSuccessCb) onSuccessCb()
        queryClient.setQueryData<AxiosResponse<Bill[]>>(
          ["bill-list", companyId],
          (old)=> {
            if (!old) return old
            else return {
              ...old,
              data: old.data.filter(bill => bill.billId !== billId)
            }
          }
        );
      },
      onError: ()=> {
        if (onErrorCb) onErrorCb()
      }
    }
  );

  interface SendToXeroProps {
    billId: string,
    xeroStatus?: "DRAFT" | "SUBMITTED" | "AUTHORIZED"
  }

  return {
    uploadSingleFile: uploadSingle.mutate,
    uploadMultipleFile: uploadMultiple.mutate,
    update: patch.mutate,
    delete: deleteBill.mutate,
    sendToXero: sendToXero.mutate
  }
}

interface MutateBillLineProps {
  billId: string;
  onSuccessCb?: () => void;
  onErrorCb?: () => void;
}
export const useMutateBillLine= ({billId, onSuccessCb,onErrorCb}: MutateBillLineProps) => {
  const companyId = useDoxleCurrentContextStore(state=> state.currentCompany?.companyId, shallow)
  const queryClient = useQueryClient()

  const create = useMutation(
    ['add-bill-line'],
    (newLine: AddBillLine) =>
      BillsAPI.post<Bill>(`Line/`, newLine, {
        headers: {'User-Company': companyId}
      }),
    {
      retry: 1,
      onSuccess: (data, variables, context) => {
        if (onSuccessCb) onSuccessCb()
        queryClient.setQueryData<AxiosResponse<Bill[]>>(
          ["bill-list", companyId],
          (old)=> {
            if (!old) return old
            else return {
              ...old,
              data: old.data.map(bill => bill.billId === billId ? data.data : bill)
            }
          })
        queryClient.setQueryData<AxiosResponse<Bill>>(
          ["bill-detail", companyId, billId],
          (old)=> {
            if (!old) return old
            else return {
              ...old,
              data: data?.data?.billId ? data.data : old.data
            }
          })
      },
      onError: ()=> {
        if (onErrorCb) onErrorCb()
      }
    }
  );

  const update = useMutation(
    ['update-bill-line'],
    (patchLine: PatchBillLine) =>
      BillsAPI.patch<Bill>(`Line/${patchLine.billLineId}/`, patchLine, {
        headers: {'User-Company': companyId}
      }),
    {
      retry: 1,
      onSuccess: (data, variables, context) => {
        if (onSuccessCb) onSuccessCb()
        queryClient.setQueryData<AxiosResponse<Bill[]>>(
          ["bill-list", companyId],
          (old)=> {
            if (!old) return old
            else return {
              ...old,
              data: old.data.map(bill => bill.billId === billId ? data.data : bill)
            }
          })
        queryClient.setQueryData<AxiosResponse<Bill>>(
          ["bill-detail", companyId, billId],
          (old)=> {
            console.log('companyId', companyId)
            console.log('billId', billId)
            console.log('old.data', old?.data)
            console.log('data?.data', data?.data)
            if (!old) return old
            else return {
              ...old,
              data: data?.data?.billId ? data.data : old.data
            }
          })
      },
      onError: ()=> {
        if (onErrorCb) onErrorCb()
      }
    }
  );

  const destroy = useMutation(
    ['update-bill-line'],
    (billLineId: string) =>
      BillsAPI.delete<Bill>(`Line/${billLineId}/`,  {
        headers: {'User-Company': companyId}
      }),
    {
      retry: 1,
      onSuccess: (data, variables, context) => {
        if (onSuccessCb) onSuccessCb()
        queryClient.setQueryData<AxiosResponse<Bill[]>>(
          ["bill-list", companyId],
          (old)=> {
            if (!old) return old
            else return {
              ...old,
              data: old.data.map(bill => bill.billId === billId ? data.data : bill)
            }
          })
        queryClient.setQueryData<AxiosResponse<Bill>>(
          ["bill-detail", companyId, billId],
          (old)=> {
            if (!old) return old
            else return {
              ...old,
              data: data?.data?.billId ? data.data : old.data
            }
          })
      },
      onError: ()=> {
        if (onErrorCb) onErrorCb()
      }
    }
  );
  return {
    create: create.mutate,
    destroy: destroy.mutate,
    update: update.mutate,
  }
}
