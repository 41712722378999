import { useIsMutating } from "@tanstack/react-query";
import { getCompanyMutationKey } from "../../../Services/QueryHooks/companyQueryAPI";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { shallow } from "zustand/shallow";
import { CircularProgress } from "@mui/material";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { Company } from "../../../Models/company";
import {StyledItemIconAndLabelSection} from "./StyledNavBarEllipsis";
import {DoxleBWIcon, SideMenuKebabIcon} from "./Asset/SideMenuIcons";

type props = {
  company: Company;
  handleSelectCompany: (e: React.MouseEvent, selectedCompany?: Company) => void;
  handleCompanyKebabIcon: (
    e: React.MouseEvent<SVGSVGElement>,
    company: Company
  ) => void;
};

const CompanyListItem = ({
  company,
  handleSelectCompany,
  handleCompanyKebabIcon,
}: props) => {
  const { currentCompany } = useDoxleCurrentContextStore(
    (state) => ({
      currentCompany: state.currentCompany,
    }),
    shallow
  );
  const styleProps = useDoxleThemeStore(
    (state) => ({
      $themeColor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
      $currentTheme: state.currentTheme,
    }),
    shallow
  );

  const isDeletingCompany =
    useIsMutating({
      mutationKey: getCompanyMutationKey("delete"),
      predicate: (query) =>
        Boolean(query.state.variables.includes(company.companyId)),
    }) > 0;

  return (
    <StyledItemIconAndLabelSection
      $disableBackground={company.companyId !== currentCompany?.companyId}
      {...styleProps}
      onClick={(e) => handleSelectCompany(e, company)}
      key={company.companyId}
    >
      {company.logo ? (
        <img src={company?.logo} height={20} width={20}/>
      ) : (
        <DoxleBWIcon
          themeColor={styleProps.$themeColor}
          containerStyle={{
            height: 20,
          }}
          scaleDirection="height"
        />
      )}
      &nbsp;&nbsp;
      <span>{company?.name ?? "Unknown company"}</span>
      {!isDeletingCompany ? (
        <SideMenuKebabIcon
          themeColor={styleProps.$themeColor}
          onClick={(e) => handleCompanyKebabIcon(e, company)}
        />
      ) : (
        <div className="spinner-container">
          <CircularProgress size={20} sx={{color: "#ff0f0f !important"}}/>
        </div>
      )}
    </StyledItemIconAndLabelSection>
  );
};

export default CompanyListItem;
