import {useParams} from "react-router-dom";
import {useMutateBill, useMutateBillLine, useRetrieveBillDetail} from "../../Services/QueryHooks/useBillsQueries";
import {useEffect, useMemo, useState} from "react";
import {Bill, BillLine, blankBill, PatchBillLine} from "../../Models/bills";
interface Props {
  initLine: BillLine
}
const useBillLine = ({initLine}:Props) => {
  const [line, setLine] = useState<BillLine>(initLine)
  const mutateBillLineQuery = useMutateBillLine({billId: line.billId});

  function isNumeric(value: string) {
    return /(^$)|(^-?\d+$)|(^-?\d+\.$)|(^-?\d+\.\d+$)/.test(value);
  }
  const handleChange = (field: keyof BillLine, e: React.ChangeEvent<HTMLInputElement>) => {
    setLine(prev => {
      if (field === "billId" || field === "billLineId" || field === "index") return prev
      let value = e.target.value
      if ((field === "quantity" || field === "lineCost" || field === "itemCost")
        && isNumeric(value.replaceAll(',', '').replace('$', ''))
      ) value = value.replaceAll(',', '').replace('$', '')
      let newLine: BillLine = {...prev}
      newLine[field] = value
      return newLine
    })
  }
  const handleKeyDown = (field: keyof BillLine, e: React.KeyboardEvent<HTMLInputElement>) => {
    console.log(e.key)
    if (
      e.key === "Escape" &&
      field !== "billId" && field !== "billLineId" && field !== "index"
    ) {
      setLine(prev => {
        let newBill: BillLine = {...prev}
        newBill[field] = initLine?.[field] ?? ""
        return newBill
      })
    }
    if (e.key === "Enter") {
      mutateBillLineQuery.update(line)
    }
  }
  const handleBlur = (field: keyof BillLine, e: React.FocusEvent<HTMLInputElement>) => {
    mutateBillLineQuery.update(line)
  }
  const handleDelete = (e: React.MouseEvent<SVGSVGElement>) => {
    mutateBillLineQuery.destroy(line.billLineId)
  }

  useEffect(()=>{
    setLine(initLine)
  }, [initLine])

  return {
    line,
    handleChange,
    handleKeyDown,
    handleBlur,
    handleDelete
  }
}

export default useBillLine
