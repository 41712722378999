import * as React from "react";
import {
  StyledCompanyContent,
  StyledDoxleLogoContainer, StyledItemIconAndLabelSection,
  StyledPopoverTableContainer,
  StyledPopoverTableContent,
  StyledPopoverTableFooter,
  StyledPopoverTableMiddleContent, StyledSelectCompanyHeaderSection,
} from "./StyledNavBarEllipsis";

import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";
import { useNavigate } from "react-router-dom";
import { useDoxleAuthStore } from "../../../DoxleGeneralStore/useDoxleAuthStore";
import { shallow } from "zustand/shallow";
import { useDoxleCurrentContextStore } from "../../../DoxleGeneralStore/useDoxleCurrentContext";
import { MouseEvent, useState } from "react";
import { Company } from "../../../Models/company";
import doxleLogo from "./Asset/doxleLogo.svg";
import {
  StyledButtonBarIcon,
  StyledFileIcon,
  StyledFontIcon,
  StyledInviteIcon,
  StyledReportIcon,
  StyledStoryIcon,
  StyledThemeIcon,
  StyledXeroIcon,
} from "./Asset/SideMenuIcons";
import useGetCompanyList from "../../../Services/QueryHooks/useGetCompanyList";


export interface IPopoverTableProps {
  handleClose: () => void;
}

export function PopoverTable({ handleClose }: IPopoverTableProps) {
  const [displayCompanies, setDisplayCompanies] = useState(false);
  const { currentCompany, setCurrentCompany } = useDoxleCurrentContextStore(
    (state) => ({
      currentCompany: state.currentCompany,
      setCurrentCompany: state.setCurrentCompany,
    }),
    shallow
  );
  const { companyList } = useGetCompanyList({});
  const handleSelectCompany = (e: MouseEvent, selectedCompany?: Company) => {
    e.stopPropagation();
    e.preventDefault();
    console.log(selectedCompany);
    setCurrentCompany(selectedCompany);
    if (selectedCompany) navigate("/");
    else navigate("/CompanySettings/");
    handleClose();
  };
  const { user, logOut } = useDoxleAuthStore(
    (state) => ({
      user: state.user,
      logOut: state.handleLogOut,
    }),
    shallow
  );
  const styleProps = useDoxleThemeStore(
    (state) => ({
      $themeColor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
      $currentTheme: state.currentTheme,
    }),
    shallow
  );

  const setTheme = useDoxleThemeStore((state) => state.setTheme);

  const themeTitle =
    styleProps.$currentTheme[0].toUpperCase() +
    styleProps.$currentTheme.substring(1);

  const handleChangeTheme = () => {
    setTheme(
      styleProps.$currentTheme === "light"
        ? "contrast"
        : styleProps.$currentTheme === "contrast"
        ? "dark"
        : "light"
    );
  };
  interface MiddleContent {
    text: string;
    icon?: string;
    disabled?: true;
    hasLine?: true;
  }
  const middleContent: MiddleContent[] = [
    // { text: "Library", icon: libraryIcon, disabled: true },
    // { text: "Our Story", icon: storyIcon, disabled: true },
    // { text: "Change Font", icon: fontIcon, disabled: true },
    // { text: "Switch to Bottom Bar", icon: buttonBarIcon, disabled: true, hasLine: true },
    // { text: "Xero Settings" },
    // { text: "Invite to Doxle", disabled: true, hasLine: true },
    // { text: "Become a member", disabled: true },
    // { text: "Recommend Features", disabled: true },
    // { text: "Apply to the Partner Program", disabled: true },
    // { text: "Register for beta release", disabled: true, hasLine: true },
    { text: themeTitle + " Theme", disabled: true },
    { text: "Font Styles", disabled: true },
    { text: "Switch to Bottom Bar", disabled: true, hasLine: true },
    { text: "Files", disabled: true },
    { text: "Xero Settings", disabled: true },
    { text: "Reports", disabled: true, hasLine: true },
    { text: "Our Story", disabled: true },
    { text: "Invite to Doxle", disabled: true, hasLine: true },
  ];

  const getSideMenuOptionIcon = (option: string) => {
    if (option.includes("Theme")) {
      return <StyledThemeIcon themeColor={styleProps.$themeColor} />;
    } else if (option === "Font Styles") {
      return <StyledFontIcon themeColor={styleProps.$themeColor} />;
    } else if (option === "Switch to Bottom Bar") {
      return <StyledButtonBarIcon themeColor={styleProps.$themeColor} />;
    } else if (option === "Files") {
      return <StyledFileIcon themeColor={styleProps.$themeColor} />;
    } else if (option === "Xero Settings") {
      return <StyledXeroIcon themeColor={styleProps.$themeColor} />;
    } else if (option === "Reports") {
      return <StyledReportIcon themeColor={styleProps.$themeColor} />;
    } else if (option === "Our Story") {
      return <StyledStoryIcon themeColor={styleProps.$themeColor} />;
    } else if (option === "Invite to Doxle") {
      return <StyledInviteIcon themeColor={styleProps.$themeColor} />;
    }
  };

  const navigate = useNavigate();
  const handleItemClick = (item: MiddleContent) => {
    if (item.text === "Xero Settings") navigate("/Xero/");
    if (item.text.includes("Theme")) {
      handleChangeTheme();
    }
  };
  return (
    <StyledPopoverTableContainer {...styleProps}>
      <StyledPopoverTableContent {...styleProps}>
        {!displayCompanies ? (
          <StyledCompanyContent {...styleProps}>
            <StyledSelectCompanyHeaderSection {...styleProps}>
              {/* <span>Current Company</span> */}
            </StyledSelectCompanyHeaderSection>
            {/* eslint-disable-next-line react/jsx-no-undef */}
            <StyledItemIconAndLabelSection
              {...styleProps}
              onClick={() => setDisplayCompanies((prev) => !prev)}
            >
              <img src={currentCompany?.logo} height={20} />
              &nbsp;&nbsp;
              <span>{currentCompany?.name ?? "Select a Company"}</span>
            </StyledItemIconAndLabelSection>
            {/* <hr style={{ marginTop: '1.5rem' }}/> */}
          </StyledCompanyContent>
        ) : (
          <StyledCompanyContent {...styleProps}>
            <StyledSelectCompanyHeaderSection {...styleProps}>
              <span>Select a Company</span>
            </StyledSelectCompanyHeaderSection>
            {companyList.map((company) => (
              <StyledItemIconAndLabelSection
                {...styleProps}
                onClick={(e) => handleSelectCompany(e, company)}
              >
                <img src={company?.logo} height={20} />
                &nbsp;&nbsp;
                <span>{company?.name ?? "Select a Company"}</span>
              </StyledItemIconAndLabelSection>
            ))}
            <StyledItemIconAndLabelSection
              {...styleProps}
              onClick={(e) => handleSelectCompany(e, undefined)}
            >
              <img src="/assets/icons/watchers_add.svg" height={20} />
              &nbsp;&nbsp;
              <span>Add a newCompany</span>
            </StyledItemIconAndLabelSection>
            <hr style={{ marginTop: "1.5rem" }} />
          </StyledCompanyContent>
        )}

        {!displayCompanies && (
          <StyledPopoverTableMiddleContent {...styleProps}>
            <ul>
              {middleContent.map((item, index) => (
                <li key={index} onClick={() => handleItemClick(item)}>
                  <div>
                    {getSideMenuOptionIcon(item.text)}
                    {<span>{item.text}</span>}
                  </div>
                  {item.hasLine && <hr style={{ marginTop: "1.5rem" }} />}
                </li>
              ))}
            </ul>
          </StyledPopoverTableMiddleContent>
        )}
        <StyledPopoverTableFooter {...styleProps} onClick={() => logOut()}>
          <span style={{ cursor: "pointer" }} className="signOut">
            Sign out
          </span>
          <span className="email">{user?.email}</span>
        </StyledPopoverTableFooter>
      </StyledPopoverTableContent>
      <StyledDoxleLogoContainer>
        <img src={doxleLogo} style={{ maxWidth: 170 }} />
      </StyledDoxleLogoContainer>
    </StyledPopoverTableContainer>
  );
}
