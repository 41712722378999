import React, { useMemo } from "react";
import { shallow } from "zustand/shallow";
import {Company} from "../../Models/company";
import {useDoxleCurrentContextStore} from "../../DoxleGeneralStore/useDoxleCurrentContext";
import CompanyQueryAPI from "./companyQueryAPI";

type Props = {
  enabled?: boolean
};

interface IGetCompanyList {
  companyList: Company[];
  isFetchingCompanyList: boolean;
  isErrorFetchingCompanyList: boolean;
  isSuccessFetchingCompanyList: boolean;
}
const useGetCompanyList = ({enabled}: Props): IGetCompanyList => {
  const { currentCompany, setCurrentCompany } = useDoxleCurrentContextStore(
    (state) => ({
      currentCompany: state.currentCompany,
      setCurrentCompany: state.setCurrentCompany,
    }),
    shallow
  );
  const onSuccessFetchingCompanyList = (companyList?: Company[]) => {
    if (companyList && !currentCompany) {
      const storedCompanyId: string | null = localStorage.getItem("currentCompanyId");
      const matchedCompany: Company | undefined =
        companyList.length > 0
          ? companyList.filter(
              (resCompany: Company) => resCompany.companyId === storedCompanyId
            )[0] ?? companyList[0]
          : undefined;
      setCurrentCompany(matchedCompany);
    }
  };
  const getCompanyQuery = CompanyQueryAPI.useGetCompanyQuery({
    company: undefined,
    enabled,
    onSuccessCb: onSuccessFetchingCompanyList,
  });

  const companyList: Company[] = useMemo(
    () =>
      getCompanyQuery.isSuccess
        ? getCompanyQuery.data?.data?.results ?? []
        : [],
    [getCompanyQuery.data]
  );
  return {
    companyList,
    isFetchingCompanyList: getCompanyQuery.isLoading,
    isErrorFetchingCompanyList: getCompanyQuery.isError,
    isSuccessFetchingCompanyList: getCompanyQuery.isSuccess,
  };
};

export default useGetCompanyList;
