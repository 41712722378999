import { shallow } from "zustand/shallow";
import {
  StyledBackButton,
  StyledChangeFontContainer,
  StyledChangeFontText,
  StyledChangeFontTitle,
  StyledFontItemContainer,
  StyledFontName,
  StyledFontStyleDisplay,
  StyledSelectFontContainer,
} from "../StyledComponentDoxleSideMenu";
import { StyledChangeFontIcon } from "./Asset/SideMenuIcons";
import { useDoxleThemeStore } from "../../../DoxleGeneralStore/useDoxleThemeStore";

type props = {
  setDisplayChangeFontTab: React.Dispatch<React.SetStateAction<boolean>>;
}

export function SelectFontTab({setDisplayChangeFontTab}: props) {
  const { $fontMode, setFontMode, ...styleProps } = useDoxleThemeStore(
    (state) => ({
      $themeColor: state.doxleThemeColor,
      $doxleFont: state.doxleFont,
      $fontMode: state.fontMode,
      setFontMode: state.setFontMode,
    }),
    shallow
  );

  return (
    <StyledChangeFontContainer>
      <StyledChangeFontTitle {...styleProps}>
      <StyledChangeFontIcon themeColor={styleProps.$themeColor} />
      <StyledChangeFontText {...styleProps}>
        Change Font
      </StyledChangeFontText>
      <StyledBackButton {...styleProps} onClick={() => {setDisplayChangeFontTab(false)}}>Back</StyledBackButton>
      </StyledChangeFontTitle>
      <StyledSelectFontContainer>
        <StyledFontItemContainer onClick={() => setFontMode("Default")}>
          <StyledFontStyleDisplay {...styleProps} $font={"Inter"}>
            Ag
          </StyledFontStyleDisplay>
          <StyledFontName {...styleProps} style={{color:$fontMode==="Default"?styleProps.$themeColor.doxleColor:""}}>Default</StyledFontName>
        </StyledFontItemContainer>

        <StyledFontItemContainer onClick={() => setFontMode("Serif")}>
          <StyledFontStyleDisplay {...styleProps} $font={"IBM Plex Serif"}>
            Ag
          </StyledFontStyleDisplay>
          <StyledFontName {...styleProps} style={{color:$fontMode==="Serif"?styleProps.$themeColor.doxleColor:""}}>Serif</StyledFontName>
        </StyledFontItemContainer>

        <StyledFontItemContainer onClick={() => setFontMode("Mono")}>
          <StyledFontStyleDisplay {...styleProps} $font={"IBM Plex Mono"}>
            Ag
          </StyledFontStyleDisplay>
          <StyledFontName {...styleProps} style={{color:$fontMode==="Mono"?styleProps.$themeColor.doxleColor:""}}>Mono</StyledFontName>
        </StyledFontItemContainer>
      </StyledSelectFontContainer>
    </StyledChangeFontContainer>
  );
}
