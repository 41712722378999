import React, { createContext, useContext, useEffect, useState } from "react";
import DoxleAPI from "../../Services/DoxleAPI";
import CookieService from "../../Services/cookieService";
import { User } from "../../Models/user";
import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";
import { shallow } from "zustand/shallow";
import { AuthQueryAPI } from "../../Services/QueryHooks/authQueryAPI";
import {useDoxleWindowStore} from "../../DoxleGeneralStore/useDoxleWindowSizeStore";
// import {useQueryClient} from "@tanstack/react-query";
// import useDoxleNotificationStore from "../../DoxleGeneralStore/useDoxleNotificationStore";

interface authContextInterface {}
const getInitialUser = () => {
  try {
    const userStr = localStorage.getItem("currentUser") || "";
    const user: User | undefined = userStr ? JSON.parse(userStr) : undefined;
    console.log("USER GET:", user);
    return user;
  } catch (e) {
    console.log(e);
    return undefined;
  }
};

const AuthContext = createContext({});
const AuthProvider = (children: any) => {
  let timer: NodeJS.Timeout | null = null;

  // const [enableExchangeTokenQuery, setEnableExchangeTokenQuery] = useState<boolean>(false);
  // const showNotification = useDoxleNotificationStore(state => state.showNotification, shallow)
  // const queryClient = useQueryClient();
  const {
    user,
    loggedIn,
    getUser,
    setFirstAuth,
    setLoggedIn,
  } = useDoxleAuthStore(
    (state) => ({
      user: state.user,
      getUser: state.getUser,
      setFirstAuth: state.setFirstAuth,
      setLoggedIn: state.setLoggedIn,
      loggedIn: state.loggedIn,
    }),
    shallow
  );
  //
  // const checkExpiry = (expiry: number) => {
  //   try {
  //     const currentTime = new Date().getTime() / 1000;
  //     return currentTime < expiry;
  //   } catch {
  //     console.error('Error Checking Expiry');
  //     return false;
  //   }
  // };
  //

  useEffect(() => {
    console.warn("Auth Provider");
  }, []);

  useEffect(() => {
    if (!user) {
      setFirstAuth(true);
      setLoggedIn(false);
    }
  }, []);

  useEffect(() => {
    if (user === undefined  && loggedIn) getUser()
  }, [user, loggedIn]);

  // const useExchangeRefreshTokenQuery = AuthQueryAPI.useExchangeRefreshToken({
  //   getStorageExchangeRFToken: getStorageRefreshToken,
  //   onErrorCb: clearAuthAsyncStorage,
  //   showNotification,
  //   onSuccessCb: onSuccessExchangeRefreshToken,
  //   enable: enableExchangeTokenQuery,
  // });
  const authContextValue: authContextInterface = {};
  return <AuthContext.Provider value={authContextValue} {...children} />;
};
const useAuth = () => useContext(AuthContext);
export { AuthProvider, useAuth };
