import { create } from "zustand";

interface DisplayStore {
  innerWidth: number;
  innerHeight: number;
  isMobile: boolean;
  isSmallScreen: boolean;
  updateValues: (updatedWindow:  Window) => void
}
const initialInnerHeight = window.innerHeight
const initialInnerWidth = window.innerWidth
export const useDoxleWindowStore = create<DisplayStore>((set, get) => ({
  innerWidth: initialInnerWidth,
  innerHeight: initialInnerHeight,
  isMobile: initialInnerWidth < 1024,
  isSmallScreen: initialInnerWidth < 300,
  updateValues: (updatedWindow: Window) => set({
    innerWidth: updatedWindow.innerWidth,
    innerHeight: updatedWindow.innerHeight,
    isMobile: updatedWindow.innerWidth < 1024,
    isSmallScreen: updatedWindow.innerWidth < 300,
  }),
}));
