import "./App.css";
import React from "react";
import {
  StyledAppContainer,
  StyledAppContentSection,
} from "./StyledComponentApp";
import { useDoxleThemeStore } from "./DoxleGeneralStore/useDoxleThemeStore";
import { usePageTransitionContextStore } from "./DoxleGeneralStore/usePageTransitionContextStore";
import { shallow } from "zustand/shallow";
import { useDoxleAuthStore } from "./DoxleGeneralStore/useDoxleAuthStore";
import { useLocation, Routes as RouterRoutes, Route } from "react-router-dom";
import { PageTransition } from "@steveeeie/react-page-transition";

import { useDoxleCurrentContextStore } from "./DoxleGeneralStore/useDoxleCurrentContext";

import dayjs from "dayjs";
import updateLocale from "dayjs/plugin/updateLocale";
import { isAppDotDoxle } from "./Services/DoxleAPI";
import BillScreen from "./Bills/BillScreen";
import DoxleHeader from "./CoreContent/DoxleBillHeader/DoxleHeader";
import {ProcessedInvoice} from "./BillReview/ProcessedInvoice";
import BillsListScreen from "./BillListScreen/BillListScreen";
import DoxleTopMenu from "./CoreContent/DoxleTopMenu/DoxleTopMenu";
import {useCompany} from "./CoreContent/Provider/CompanyProvider";

dayjs.extend(updateLocale);

dayjs.updateLocale("en", {
  weekStart: 1,
});

function App() {
  const { preset, enterAnimation, exitAnimation } =
    usePageTransitionContextStore(
      (state) => ({
        preset: state.preset,
        enterAnimation: state.enterAnimation,
        exitAnimation: state.exitAnimation,
      }),
      shallow
    );

  const loggedIn = useDoxleAuthStore(state=> state.loggedIn , shallow);
  const doxleThemeColor = useDoxleThemeStore(state => state.doxleThemeColor, shallow);
  const currentMenuPos = useDoxleCurrentContextStore(state=> state.currentMenuPosition, shallow);

  const location = useLocation();

  const topMenuEnabled = Boolean(
    !window.location.href.includes("/ShareProject/")
  );
  // Home and login
  if (!isAppDotDoxle())
    return (
      <StyledAppContainer $themeColor={doxleThemeColor}>
        <StyledAppContentSection/>
      </StyledAppContainer>
    );
  // Projects and local testing
  return (
    <StyledAppContainer $themeColor={doxleThemeColor}>
      {loggedIn && topMenuEnabled && currentMenuPos === "Top" && (
        <DoxleTopMenu />
      )}
      <StyledAppContentSection>
        <PageTransition
          preset={preset}
          transitionKey={location.pathname}
          enterAnimation={enterAnimation}
          exitAnimation={""}
        >
          <RouterRoutes location={location}>
            <Route path={"/"} element={<BillsListScreen />}/>
            <Route path={"Upload/"} element={<BillScreen />}/>
            <Route path={"Review/:billId/"} element={<ProcessedInvoice />}/>
          </RouterRoutes>
        </PageTransition>
      </StyledAppContentSection>

      {/*</div>*/}
    </StyledAppContainer>
  );
}

export default App;
