import React from "react";
import {
  StyledCloseButton,
  StyledFileInfoContainer,
  StyledFileName,
} from "./Styles/StyledBills";
import { useDoxleThemeStore } from "../DoxleGeneralStore/useDoxleThemeStore";
import { getFileExtension } from "./FileUploadPage";

type Props = {
  file: File;
  index: number;
  removeFile: (index: number) => void;
};

const AddedFileItems = ({ file, index, removeFile }: Props) => {
  const { doxleThemeColor, currentTheme, doxleFont } = useDoxleThemeStore(
    (state) => ({
      doxleThemeColor: state.doxleThemeColor,
      doxleFont: state.doxleFont,
      currentTheme: state.currentTheme,
    })
  );
  return (
    <StyledFileInfoContainer
      $themeColor={doxleThemeColor}
      $doxleFont={doxleFont}
    >
      {getFileExtension(file.name) === "pdf" ? (
        <img
          src="/assets/icons/adobe-pdf-icon.svg"
          style={{ width: "30px", height: "30px", aspectRatio: 1 }}
        />
      ) : (
        <img
          src={URL.createObjectURL(file)}
          style={{ width: "30px", height: "30px", aspectRatio: 1 }}
        />
      )}

      <StyledFileName $themeColor={doxleThemeColor} $doxleFont={doxleFont}>
        {file.name}
      </StyledFileName>
      <StyledCloseButton
        onClick={() => {
          removeFile(index);
        }}
      >
        <img
          key={"watcher-img-" + index}
          src="/assets/icons/close_button_fill.svg"
          style={{ width: 20, height: 20 }}
        />
      </StyledCloseButton>
    </StyledFileInfoContainer>
  );
};

export default React.memo(AddedFileItems);
