import styled from "styled-components";
import {
  DoxleFont,
  DoxleTheme,
  DoxleThemeColor,
} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import {editRgbaAlpha, TRgbaFormat} from "../../../Utilities/FunctionUtilities";
import { SVGProps } from "react";

export const StyledPopoverTableContainer = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  /* width:32.7rem; */
  /* height: 58.7rem;  */
  /* background: ${(p) => p.$themeColor.primaryContainerColor}; */
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
export const StyledPopoverTableLoginRecord = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$themeColor.primaryFontColor,
      alpha: "0.5",
    })};
  font-size: 1.1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 118.9%; /* 13.079px */
  letter-spacing: -0.022rem;
  padding: 0 0.5rem;
  /* overflow: hidden;
    text-overflow: ellipsis; */
  /* white-space: nowrap; */
  /* max-width: 80%; */
`;
export const StyledPopoverTableContent = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  span {
    display: block;
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.7",
      })};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 500;
    line-height: 118.9%; /* 15.457px */
    letter-spacing: -0.026rem;
    padding: 0.3rem 0.2rem;
  }
`;
export const StyledPopoverTableFooter = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  position: absolute;
  bottom: 20px;

  .signOut {
    font-size: 1.4rem;
    color: ${(p) => p.$themeColor.primaryFontColor};
  }

  .email {
    font-size: 1.4rem;
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.6",
      })};
  }
`;

export const StyledCompanyContent = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 100%;
  letter-spacing: -0.026rem;
  margin-bottom: 1rem;
  hr {
    border: none;
    margin-left: -1rem;
    margin-bottom: 1.5rem;
    width: 21.6rem;
    height: 0.1rem;
    background: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryDividerColor,
        alpha: "0.5",
      })};
  }
`;

export const StyledPopoverTableMiddleContent = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  margin-bottom: 8rem;
  ul {
    padding: 0;
  }
  li {
    list-style: none;
    padding: 0;
  }
  div {
    display: flex;
    padding: 0.5rem 0px;
    cursor: pointer;
    height: 25px;
  }
  img {
    width: 1.8rem;
    height: 1.7rem;
    fill: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.7",
      })};
    stroke-width: 0.03rem;
    stroke: ${(p) => p.$themeColor.primaryContainerColor};
    margin-right: 0.5rem;
    /* display: inline-block; */
  }
  span {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.7",
      })};
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.3rem;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 15.457px */
    letter-spacing: -0.026rem;
    margin-left: 10px;
    /* &:hover{
            color:${(p) => p.$themeColor.primaryHoverColor}
        } */
  }
  hr {
    border: none;
    margin-left: -1rem;
    margin-bottom: 1.5rem;
    width: 21.6rem;
    height: 0.1rem;
    background: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryDividerColor,
        alpha: "0.5",
      })};
  }
`;

export const NavBarEllipsisIcon = (
  props: SVGProps<SVGElement> & DoxleThemeColor
) => (
  <svg
    width="21"
    height="6"
    viewBox="0 0 21 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="Shape"
      d="M4.9 2.625C4.9 4.07475 3.8031 5.25 2.45 5.25C1.0969 5.25 0 4.07475 0 2.625C0 1.17525 1.0969 0 2.45 0C3.8031 0 4.9 1.17525 4.9 2.625ZM12.95 2.625C12.95 4.07475 11.8531 5.25 10.5 5.25C9.1469 5.25 8.05 4.07475 8.05 2.625C8.05 1.17525 9.1469 0 10.5 0C11.8531 0 12.95 1.17525 12.95 2.625ZM18.55 5.25C19.9031 5.25 21 4.07475 21 2.625C21 1.17525 19.9031 0 18.55 0C17.1969 0 16.1 1.17525 16.1 2.625C16.1 4.07475 17.1969 5.25 18.55 5.25Z"
      fill={props.primaryFontColor}
    />
  </svg>
);

export const StyledDoxleLogoContainer = styled.div`
  margin-top: 3rem;
  margin-left: 2rem;
`;

export const StyledItemIconAndLabelSection = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $currentTheme: DoxleTheme;
  $disableBackground?: boolean;
}>`
  display: flex;
  flex-direction: row;
  padding: 8px 12px;
  border-radius: 2px;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 3px;
  margin-bottom: 10px;
  height: 30px;
  &:hover {
    ${(p) =>
  p.$disableBackground
    ? `background-color: ${p.$themeColor.primaryHoverColor};`
    : "cursor: default;"}
  }
  ${(p) =>
  p.$disableBackground
    ? `border: 1px solid ${p.$themeColor.primaryDividerColor};`
    : `background-color: ${
      p.$currentTheme !== "dark"
        ? "rgba(241, 242, 245, 1)"
        : "rgba(255,255,255,0.8)"
    };`}

  span {
    color: ${(p) =>
  p.$disableBackground
    ? p.$themeColor.primaryFontColor
    : "rgba(0, 0, 0, 0.7)"};
    font-size: 14px;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 500;
    line-height: 118.9%;
    letter-spacing: -0.28px;
    text-transform: capitalize;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .spinner-container {
    overflow: hidden
  }
`;
export const StyledSelectCompanyHeaderSection = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 0.5rem;
  span {
    color: ${(p) =>
  editRgbaAlpha({
    rgbaColor: p.$themeColor.primaryFontColor as TRgbaFormat,
    alpha: "0.7",
  })};
    font-size: 14px;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 500;
    line-height: 118.9%;
    letter-spacing: -0.28px;
    text-transform: capitalize;
  }
`;
