import { Grow } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { toast } from "react-toastify";
import { create } from "zustand";
import DownloadNotification from "../DoxleDesignPattern/Notification/DownloadNotification";

interface NotificationStore {
  showNotification: (
    message: string,
    messageType: "success" | "error",
    extraMessage?: string,
    duration?: number
  ) => void;

  showDownloadResourceNotification: (
    message: string,
    messageType: "success" | "error" | "loading",
    extraMessage?: string,
    duration?: number
  ) => void;
}

const useDoxleNotificationStore = create<NotificationStore>((set, get) => ({
  showNotification: (
    message: string,
    messageType: "success" | "error",
    extraMessage?: string,
    duration?: number
  ) => {
    enqueueSnackbar(message, {
      variant: "textNotification",
      extraDescription: extraMessage,
      messageType: messageType,

      autoHideDuration: duration ? duration : 800,
      TransitionProps: {
        direction: "up",
        timeout: {
          enter: 400,
          exit: 400,
        },
      },

      anchorOrigin: {
        vertical: "bottom",
        horizontal: "right",
      },
    });
  },

  showDownloadResourceNotification: (
    message: string,
    status: "success" | "error" | "loading",
    extraContent?: string,
    duration?: number
  ) => {
    // enqueueSnackbar(message, {
    //   variant: "downloadResourceNotification",
    //   extraContent: extraContent,
    //   status: status,
    //   autoHideDuration: duration ?? null,
    //   TransitionProps: {
    //     direction: "up",
    //   },
    //   anchorOrigin: {
    //     vertical: "bottom",
    //     horizontal: "right",
    //   },
    //   key: "ProjectDownloadNotification",
    //   persist: true,
    //   preventDuplicate: true,
    // });
  },
}));

export default useDoxleNotificationStore;
