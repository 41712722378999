import { create } from "zustand";
export type DoxleMainMenu =
  | "Inbox"
  | "Budgets"
  | "Bookings"
  | "Inspections"
  | "Files"
  | "Noticeboard"
  | "Projects"
  | "Draw"
  | "Drop";

export type DoxleCustomiseMenu =
  | "Projects"
  | "Orders"
  | "Invoices"
  | "Profit & Loss"
  | "Cashflow"
  | "Contacts"
  | "Pricebook";
export type DoxleSettingMenu =
  | "Company"
  | "Users"
  | "Themes"
  | "Billing"
  | "Security"
  | "Log Out";

export interface MainMenuListItem {
  menuItemText: DoxleMainMenu;
  display: boolean;
  disabled?: boolean;
}
export interface CustomiseMenuListItem {
  menuItemText: DoxleCustomiseMenu;
  display: boolean;
  disabled?: boolean;
}
interface DoxleMainMenuStore {
  mainMenuList: MainMenuListItem[];
  handleToggleDisplayMainMenuList: (props: MainMenuListItem) => void;
  setMainMenuList: (menuList: MainMenuListItem[]) => void;
  customiseMenuList: CustomiseMenuListItem[];
  handleToggleDisplayCustomiseMenuList: (props: CustomiseMenuListItem) => void;
  setCustomiseMenuList: (menuList: CustomiseMenuListItem[]) => void;
  currentSelectedMainMenu: MainMenuListItem | CustomiseMenuListItem;
  setCurrentSelectedMainMenu: (
    item: MainMenuListItem | CustomiseMenuListItem
  ) => void;

  settingMenu: DoxleSettingMenu[];
  showSideMenu: boolean;
  setShowSideMenu: (show: boolean) => void;
}

export const FIXED_MAIN_MENU_LIST: MainMenuListItem[] = [
  { menuItemText: "Noticeboard", display: true },
  { menuItemText: "Projects", display: true },
  { menuItemText: "Draw", display: true, disabled: true },
  { menuItemText: "Drop", display: true, disabled: true },
  { menuItemText: "Files", display: false },
];

export const INITIAL_MAIN_MENU_LIST: MainMenuListItem[] = [
  { menuItemText: "Inbox", display: true },
  { menuItemText: "Budgets", display: false },
  { menuItemText: "Bookings", display: true },
  { menuItemText: "Inspections", display: true },
  { menuItemText: "Files", display: false },
];

const INITIAL_CUSTOMISE_MENU_LIST: CustomiseMenuListItem[] = [
  { menuItemText: "Projects", display: false },
  { menuItemText: "Orders", display: false },
  { menuItemText: "Invoices", display: false },
  { menuItemText: "Profit & Loss", display: false },
  { menuItemText: "Cashflow", display: false },
  { menuItemText: "Contacts", display: false },
  { menuItemText: "Pricebook", display: false },
];
const initialUrl = window.location.href;
const initialSelectedMenuItem = initialUrl.includes("Budgets")
  ? "Budgets"
  : initialUrl.includes("Bookings")
  ? "Bookings"
  : initialUrl.includes("Inspections")
  ? "Inspections"
  : initialUrl.includes("Files")
  ? "Files"
  : initialUrl.includes("Project")
  ? "Projects"
  : initialUrl.includes("Orders")
  ? "Orders"
  : initialUrl.includes("Invoices")
  ? "Invoices"
  : initialUrl.includes("Profit & Loss")
  ? "Profit & Loss"
  : initialUrl.includes("Cashflow")
  ? "Cashflow"
  : initialUrl.includes("Contacts")
  ? "Contacts"
  : initialUrl.includes("Pricebook")
  ? "Pricebook"
  : initialUrl.includes("Draw")
  ? "Draw"
  : initialUrl.includes("Drop")
  ? "Drop"
  : initialUrl.includes("Noticeboard")
  ? "Noticeboard"
  : "Projects";
export const useDoxleMainMenuStore = create<DoxleMainMenuStore>((set, get) => ({
  // mainMenuList: localStorage.getItem("lastMainMenuList")
  //   ? (JSON.parse(
  //       localStorage.getItem("lastMainMenuList")!
  //     ) as MainMenuListItem[])
  //   : INITIAL_MAIN_MENU_LIST,
  mainMenuList: FIXED_MAIN_MENU_LIST,
  handleToggleDisplayMainMenuList: (props: MainMenuListItem) =>
    set((state) => ({
      mainMenuList: [
        ...state.mainMenuList.map((item) => {
          if (item.menuItemText === props.menuItemText)
            return { ...item, display: props.display };
          else return item;
        }),
      ],
    })),
  setMainMenuList: (menuList: MainMenuListItem[]) => {
    set((state) => ({ mainMenuList: menuList }));
    localStorage.setItem("lastMainMenuList", JSON.stringify(menuList));
  },
  customiseMenuList: localStorage.getItem("lastCustomiseMenuList")
    ? (JSON.parse(
        localStorage.getItem("lastCustomiseMenuList")!
      ) as CustomiseMenuListItem[])
    : INITIAL_CUSTOMISE_MENU_LIST,
  handleToggleDisplayCustomiseMenuList: (props: CustomiseMenuListItem) =>
    set((state) => ({
      customiseMenuList: [
        ...state.customiseMenuList.map((item) => {
          if (item.menuItemText === props.menuItemText)
            return { ...item, display: props.display };
          else return item;
        }),
      ],
    })),
  setCustomiseMenuList: (menuList: CustomiseMenuListItem[]) => {
    set((state) => ({ customiseMenuList: menuList }));
    localStorage.setItem("lastCustomiseMenuList", JSON.stringify(menuList));
  },
  currentSelectedMainMenu: {
    menuItemText: initialSelectedMenuItem,
    display: true,
  },
  setCurrentSelectedMainMenu: (
    item: MainMenuListItem | CustomiseMenuListItem
  ) => set({ currentSelectedMainMenu: item }),
  settingMenu: ["Company", "Users", "Themes", "Billing", "Security", "Log Out"],
  showSideMenu: false,
  setShowSideMenu: (show: boolean) => set({ showSideMenu: show }),
}));
