import {StyledEdittableInput} from "./StyledProcessedInvoice";
import {useDoxleThemeStore} from "../DoxleGeneralStore/useDoxleThemeStore";
import {BillLine} from "../Models/bills";
import useBillLine from "./Hooks/useBillLine";
import {useState} from "react";
import {TrashIcon} from "@heroicons/react/24/outline";
interface Props {
  initLine: BillLine
}
const ProcessedBillLine = ({initLine}: Props) => {
  const [hover, setHover] = useState<boolean>(false)
  const { doxleThemeColor, doxleFont } = useDoxleThemeStore((state) => ({
    doxleThemeColor: state.doxleThemeColor,
    doxleFont: state.doxleFont,
  }));

  const {
    line,
    handleChange,
    handleKeyDown,
    handleBlur,
    handleDelete
  } = useBillLine({initLine})

  return (
    <tr key={line.billLineId} onPointerEnter={()=>setHover(true)} onPointerLeave={()=>setHover(false)}>
      <td>
        <StyledEdittableInput
          $themeColor={doxleThemeColor}
          value={line.description}
          onChange={(e) => handleChange("description", e)}
          onKeyDown={(e) => handleKeyDown("description", e)}
          onBlur={(e) => handleBlur("description", e)}
        />
      </td>
      <td>
        <StyledEdittableInput
          $themeColor={doxleThemeColor}
          value={line.quantity}
          onChange={(e) => handleChange("quantity", e)}
          onKeyDown={(e) => handleKeyDown("quantity", e)}
          onBlur={(e) => handleBlur("quantity", e)}
        />
      </td>
      <td>
        <StyledEdittableInput
          $themeColor={doxleThemeColor}
          value={line.itemCost}
          onChange={(e) => handleChange("itemCost", e)}
          onKeyDown={(e) => handleKeyDown("itemCost", e)}
          onBlur={(e) => handleBlur("itemCost", e)}
        />
      </td>
      <td>
        <StyledEdittableInput
          $themeColor={doxleThemeColor}
          value={line.lineCost}
          // onChange={(e) => handleChange("lineCost", e)}
          // onKeyDown={(e) => handleKeyDown("lineCost", e)}
          // onBlur={(e) => handleBlur("lineCost", e)}
        />
      </td>
      <td className={"icon-cell"}>
        {hover && <TrashIcon onClick={handleDelete}/>}
      </td>
    </tr>
  )
}
export default ProcessedBillLine
