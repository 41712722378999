import {styled} from "styled-components";
import {DoxleFont, DoxleThemeColor} from "../DoxleGeneralStore/useDoxleThemeStore";
import {editRgbaAlpha} from "../Utilities/FunctionUtilities";

export const StyledBillsListPage = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: 100%;
  height: 100%;
  background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
  color: ${(p) => p.$themeColor.primaryFontColor};
`
export const StyledBillsListHeader = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  padding: 3rem 0 1.5rem 0;
  margin-left: min(10%, 200px);
  width: 100%;
  font-family: ${(p) => p.$doxleFont.primaryTitleFont};
  font-weight: 900;
  font-size: 18px;
`
export const StyledBillsListContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  display: flex;
  flex-direction: column;
  margin: 0 auto 0 min(10%, 200px);
  width: 80%;
  max-width: 1400px;
  height: 100%;
`
export const BillLine = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  background-color: ${(p) => p.$themeColor.primaryContainerColor};
  border: 1px solid ${(p) => editRgbaAlpha({
    rgbaColor: p.$themeColor.doxleColor,
    alpha: '0.3'
  })};
  border-radius: 0 5px 5px 5px;
  width: calc(100% - 2.6rem, - 2px);
  padding: 1.3rem;
  margin: 0.6rem 0rem;
  &:hover {
    background-color: ${(p) => p.$themeColor.primaryHoverColor};
  }
  cursor: pointer;
  font-family: ${(p) => p.$doxleFont.primaryTitleFont};
  font-size: max(13px, 1.3rem);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .file {
    flex: 1;
  }
  
  .amount{
    width: 30%
  }
  .status{
    width: 30%
  }
`


