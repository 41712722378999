import { SnackbarContent } from "notistack";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";

export const RootTextOnlyNotificationContainer = styled(SnackbarContent)<{
  $backgroundColor: string;
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  display: flex;
  flex-direction: row !important;
  align-items: center;

  background-color: ${(p) => p.$themeColor.primaryFontColor};

  min-height: 30px;

  border-radius: 4px;
  padding: 10px 18px;
  span {
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: ${(p) => p.$themeColor.primaryReverseFontColor};
    text-align: center;
    flex-wrap: wrap;
  }
`;

export const DownloadResourceNotificationContainer = styled(SnackbarContent)<{
  $backgroundColor: string;
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.5rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  background-color: ${(p) => p.$backgroundColor};
  color: ${(p) => p.$themeColor.primaryReverseFontColor};
  min-height: 40px;
  border-radius: 1rem;
  padding: 1rem;
  position: relative;
`;

export const DownloadNotificationContainer = styled(SnackbarContent)<{
  $backgroundColor: string;
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-style: normal;
  font-weight: 700;
  font-size: 1.3rem;
  line-height: 1.5rem;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  background-color: ${(p) => p.$backgroundColor};
  color: ${(p) => p.$themeColor.primaryReverseFontColor};
  min-height: 40px;
  border-radius: 1rem;
  padding: 1rem;
  position: relative;
`;

export const StyledExtraDesceiptionText = styled.span<{
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-style: normal;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.4rem;
  text-transform: capitalize;
  color: ${(p) => p.$themeColor.primaryReverseFontColor};
`;

export const StyledMessageContainer = styled.div<{
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const StyledMessageTitle = styled.div<{
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-weight: 500;
  font-size: 1.4rem;
  text-transform: capitalize;
  color: ${(p) => p.$themeColor.primaryReverseFontColor};
  margin-bottom: 0.5rem;
`;

export const StyledMessageContent = styled.div<{
  $doxleFont: DoxleFont;
  $themeColor: DoxleThemeColor;
}>`
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-weight: 400;
  font-size: 1.1rem;
  text-transform: capitalize;
  color: ${(p) => p.$themeColor.primaryReverseFontColor};
`;

export const StyledProgressContainer = styled.div``;
