import { useNavigate } from "react-router-dom";

const useNavigateToLastApp = () => {
  const lastUsed = localStorage.getItem("lastUsedApp");
  const apps = ["Inventory", "Noticeboard", "Projects", "Draw", "Bills"];
  const navigate = useNavigate();
  return () => {
    if (lastUsed && apps.includes(lastUsed)) {
      if (window.location.hostname.toLowerCase().includes("doxle")) {
        window.open(`https://${lastUsed.toLowerCase()}.doxle.com/`, "_self");
      } else {
        navigate(`/${lastUsed}/`);
      }
    } else {
      if (window.location.hostname.toLowerCase().includes("doxle")) {
        window.open("https://projects.doxle.com/", "_self");
      } else {
        navigate("/Projects/");
      }
    }
  };
};
export default useNavigateToLastApp;
