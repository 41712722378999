import FileUploadPage from "./FileUploadPage";
import { ProcessedInvoice } from "../BillReview/ProcessedInvoice";
import { SelectPage } from "./SelectPage";
import { useBillStore } from "./Store/useBillStore";

export default function BillScreen() {
  const { currentStage } = useBillStore((state) => ({
    currentStage: state.currentStage,
  }));

  if (currentStage === "upload") {
    return <FileUploadPage />;
  } else if (currentStage === "preview") {
    return <SelectPage />;
  } else if (currentStage === "processing") {
    return <ProcessedInvoice />;
  } else {
    return <FileUploadPage />;
  }
}
