import { motion } from "framer-motion";
import styled from "styled-components";
import {
  DoxleFont,
  DoxleTheme,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import { editRgbaAlpha, TRgbaFormat } from "../../Utilities/FunctionUtilities";
import { IconButton, TextField, TextFieldProps } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { SVGProps } from "react";
import Button from "@mui/material/Button";

export const StyledDoxleTopMenuContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $currentTheme: DoxleTheme;
  $position: string;
}>`
  width: calc(100% - 16px);
  height: 55px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  background-color: ${(p) =>
    p.$currentTheme === "light"
      ? "rgba(255,255,255,1)"
      : p.$themeColor.primaryContainerColor};
  z-index: 2;
  padding: 0px 8px;
  ${(p) => p.$position==="Top" && `border-bottom:1px solid  ${p.$themeColor.primaryDividerColor};`}
  ${(p) => p.$position==="Bottom" && `border-top:1px solid  ${p.$themeColor.primaryDividerColor};`}
`;

export const StyledDoxleSideMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 2rem;
    cursor: pointer;
  }
`;
export const StyledDoxleSideMenuSearchField = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    min-width: 10rem;
    width: 21.8rem;
    max-width: 45rem;
    height: 2.3rem;
    border-radius: 0.2rem;
    margin: auto 2rem;
    background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
    position: relative;
  }
  input {
    /* padding: 1rem !important; */
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-size: 1.2rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.024rem;
    color: ${(p) => p.$themeColor.primaryFontColor};
    padding: 0.6rem 0 0.3rem 2.7rem;
    border: none;
    background-color: transparent;
    width: calc(100% - 2.7rem);

    &::placeholder {
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-size: 1.2rem;
      font-style: normal;
      font-weight: 600;
      line-height: 118.9%;
      letter-spacing: -0.024rem;
      color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryFontColor,
          alpha: "0.4",
        })};
    }
  }
`;
export const StyledDoxleSideMenuSearchIcon = styled.div`
  position: absolute;
  left: 0.6rem;
  top: 0.6rem;
`;
export const StyledMenuSection = styled.div`
  //flex: 1;
`;
export const StyledMenuRightSection = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  label {
    display: flex;
    align-items: center;
    padding: 1.2rem 1.3rem 0.7rem 0;
  }
`;
export const StyledMenuThemeAndEllipsis = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  display: flex;
  align-items: center;
`;

export const StyledTopMenuIconDiv = styled.div<{
  $variant: "first" | "last" | "middle";
  $hoverColor?: TRgbaFormat;
  $themeColor: DoxleThemeColor;
}>`
  margin: ${(p) =>
    p.$variant === "first"
      ? "0.8rem 0.4rem 0.6rem 0.8rem"
      : p.$variant === "last"
      ? "0.8rem 0.8rem 0.6rem 0.4rem"
      : "0.8rem 0.4rem 0.6rem 0.4rem"};
  border-radius: 0.5rem;
  cursor: pointer;
  display: flex;
  font-size: 1.6rem;
  color: ${(p) => p.$themeColor.primaryFontColor};

  ${(p) =>
    p.$hoverColor
      ? `
    &:hover{
      background-color: ${p.$hoverColor};
    }
  `
      : ``}
`;

interface ICustomTextFieldProps
  extends Omit<
    TextFieldProps<"standard">,
    "onDragStart" | "onAnimationStart" | "onDragEnd" | "onDrag"
  > {
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $currentTheme: DoxleTheme;
}
export const StyledDoxleGlobalSearchTextField = styled(
  ({
    $themeColor,
    $doxleFont,
    $currentTheme,
    ...props
  }: ICustomTextFieldProps) => <TextField {...props} />
)`
  && {
    max-width: 240px;
    background-color: ${(p) => p.$themeColor.primaryTextFieldBackgroundColor};
    border-radius: 21px;
    padding: 4px 14px 4px 20px;
    @media (min-width: 700px) {
      width: 26.1rem;
    }
  }
  input {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor,
        alpha: "0.6",
      })};
    font-size: 14px;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.024rem;

    &::placeholder {
      color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryFontColor,
          alpha: "0.4",
        })};
      font-size: 14px;
      opacity: 1;
      font-family: ${(p) => p.$doxleFont.primaryFont};
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: -0.024rem;
      padding: 0px !important;
    }
  }
  & .MuiOutlinedInput-root {
    padding: 0px !important;
  }
  & .MuiOutlinedInput-input {
    padding: 0px !important;
  }
  & .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }

  & .MuiInput-root:before {
    border: none !important;
  }
  & .MuiInput-root:after {
    border: none !important;
  }
`;

export const StyledInputEndornment = styled(InputAdornment)`
  && {
    margin-right: 0.8rem;
    justify-content: center;
    align-items: center;
  }
`;

export const StyledEndInputEndornment = styled(InputAdornment)`
  && {
    margin-left: 0.8rem;
    justify-content: end;
    align-items: center;
    cursor: pointer;
  }
`;

export const StyledNavBarShareButton = styled(motion(Button))<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  && {
    height: 30px;
    padding: 4px 10px !important;
    background: none;
    border-radius: 3px !important;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 14px;
    letter-spacing: 0.05em;
    color: ${(p) => p.$themeColor.primaryFontColor};
    text-transform: none;
    background-color: ${(p) => "transparent"};
    transition: all 0.4s ease;
  }
  &:hover {
    background: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.doxleColor,
        alpha: "0.7",
      })} !important;
    color: white;
  }
`;

interface CustomSVGProps extends SVGProps<SVGSVGElement> {
  themeColor: DoxleThemeColor;
}

export const NavBarFolderIcon = ({ themeColor, ...props }: CustomSVGProps) => {
  return (
    <svg
      width="25"
      height="20"
      viewBox="0 0 25 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.36938 17.7713L3.58527 9.10799C3.8736 7.98069 4.91139 7.18969 6.10208 7.18969H21.7572V6.34361C21.7572 4.94564 20.5958 3.81236 19.1631 3.81236H12.5625L9.81743 1.58497C9.35156 1.20696 8.76478 1 8.15889 1H3.59859C2.1663 1 1.00507 2.13275 1.00457 3.53038L1 16.4679C0.999832 16.9446 1.13472 17.3905 1.36938 17.7713ZM1.36938 17.7713C1.36823 17.7758 1.41889 17.5775 1.36938 17.7713ZM3.01627 18.3013C2.92547 18.6563 3.20063 19 3.57556 19H19.9924C20.9182 19 21.7251 18.3852 21.9497 17.5088L23.9825 9.57614C24.0735 9.22112 23.7983 8.87719 23.4233 8.87719H6.10208C5.70518 8.87719 5.35925 9.14086 5.26314 9.51662L3.01627 18.3013Z"
        stroke={themeColor.primaryFontColor}
        strokeWidth="1.2"
      />
    </svg>
  );
};

export const NavBarSearchIcon = ({ themeColor, ...props }: CustomSVGProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.75 11C3.75 6.99594 6.99594 3.75 11 3.75C15.0041 3.75 18.25 6.99594 18.25 11C18.25 15.0041 15.0041 18.25 11 18.25C6.99594 18.25 3.75 15.0041 3.75 11ZM11 2.25C6.16751 2.25 2.25 6.16751 2.25 11C2.25 15.8325 6.16751 19.75 11 19.75C13.1462 19.75 15.112 18.9773 16.6342 17.6949L19.4697 20.5303C19.7626 20.8232 20.2374 20.8232 20.5303 20.5303C20.8232 20.2374 20.8232 19.7626 20.5303 19.4697L17.6949 16.6342C18.9773 15.112 19.75 13.1462 19.75 11C19.75 6.16751 15.8325 2.25 11 2.25Z"
        fill={themeColor.primaryFontColor}
      />
    </svg>
  );
};

export const NavBarCloseIcon = ({ themeColor, ...props }: CustomSVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      focusable="false"
      viewBox="0 0 24 24"
      {...props}
    >
      <path
        d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
        stroke={themeColor.primaryFontColor}
      />
    </svg>
  );
};

export const NavBarBackIcon = ({ themeColor, ...props }: CustomSVGProps) => {
  return (
    <svg
      width="24"
      height="24"
      focusable="false"
      viewBox="0 0 24 24"
      data-testid="ArrowBackIcon"
      {...props}
    >
      <path
        d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z"
        stroke={themeColor.primaryFontColor}
      />
    </svg>
  );
};

export const StyledMenuLeftSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  justify-content: space-between;
`;

export const StyledLastLogin = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  font-size: 1.4rem;
  color: ${(p) =>
    editRgbaAlpha({ rgbaColor: p.$themeColor.primaryFontColor, alpha: "0.3" })};
  font-family: ${(p) => p.$doxleFont.secondaryFont};
`;

export const StyledAddBtn = styled.button<{
  $themeColor: DoxleThemeColor;
}>`
  border-radius: 3px;
  width: 80px;
  height: 2.7rem;
  color: ${(p) => p.$themeColor.primaryReverseFontColor};
  background-color: ${(p) => p.$themeColor.primaryFontColor};
  border: none;
  cursor: pointer;
`;
