import { create } from "zustand";

export type UploadDrawingStage = "upload" | "preview" | "processing" | "result";

interface BillStore {
    pdfFiles: File[];
    setPdfFiles: (pdfFiles: File[]) => void;
    imageFiles: File[];
    setImageFiles: (imageFiles: File[]) => void;
    currentStage: UploadDrawingStage;
    setCurrentStage: (stage: UploadDrawingStage) => void;
}

export const useBillStore = create<BillStore>((set, get) => ({
    pdfFiles: [],
    imageFiles: [],
    setPdfFiles: (pdfFiles: File[]) => {
        set({pdfFiles: pdfFiles})
    },
    setImageFiles: (imageFiles: File[]) => {
        set({imageFiles: imageFiles})
    },
    currentStage: "upload",
    setCurrentStage: (stage: UploadDrawingStage) => {
        set({currentStage: stage});
    }
}));