import { useEffect, useMemo, useState, MouseEvent } from "react";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import { useDoxleMainMenuStore } from "../../DoxleGeneralStore/useDoxleMainMenuStore";
import {
  StyledMenuThemeAndEllipsis,
  StyledDoxleTopMenuContainer,
  StyledDoxleGlobalSearchTextField,
  StyledInputEndornment,
  StyledTopMenuIconDiv,
  StyledNavBarShareButton,
  NavBarBackIcon,
  StyledMenuRightSection,
  StyledMenuLeftSection,
  StyledAddBtn,
} from "./StyledComponentDoxleTopMenu";

import { useLocation, useNavigate } from "react-router-dom";
import NavBarEllipsis from "./NavBarEllipsis/NavBarEllipsis";
import { TopMenuSearchIcon, TopMenuMailIcon } from "./DoxleTopMenuIcon";
import { useDoxleCurrentContextStore } from "../../DoxleGeneralStore/useDoxleCurrentContext";
import { useDoxleAuthStore } from "../../DoxleGeneralStore/useDoxleAuthStore";
import moment from "moment";
import Tooltip from "@mui/material/Tooltip";
import { Typography } from "@mui/material";
import { IoArrowBackOutline } from "react-icons/io5";
import { motion } from "framer-motion";
import { DoxleMailIcon } from "../../DoxleDesignPattern/DoxleGeneralIcons";
type Props = {};

const DoxleTopMenu = (props: Props) => {
  // const [displaySearchInput, setDisplaySearchInput] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>("");

  const { doxleThemeColor, doxleFont, currentTheme, setTheme } =
    useDoxleThemeStore(
      (state) => ({
        doxleThemeColor: state.doxleThemeColor,
        doxleFont: state.doxleFont,
        currentTheme: state.currentTheme,
        setTheme: state.setTheme,
      }),
      shallow
    );

  const { user } = useDoxleAuthStore(
    (state) => ({
      user: state.user,
    }),
    shallow
  );

  const { setCurrentSearchText, projectId, currentMenuPostion } =
    useDoxleCurrentContextStore(
      (state) => ({
        setCurrentSearchText: state.setCurrentSearchText,
        projectId: state.currentProject?.projectId,
        currentMenuPostion: state.currentMenuPosition,
      }),
      shallow
    );

  const {
    mainMenuList,
    currentSelectedMainMenu,
    customiseMenuList,
  } = useDoxleMainMenuStore(
    (state) => ({
      mainMenuList: state.mainMenuList,
      currentSelectedMainMenu: state.currentSelectedMainMenu,
      customiseMenuList: state.customiseMenuList,
    }),
    shallow
  );
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.setItem("lastMainMenuList", JSON.stringify(mainMenuList));
  }, [mainMenuList]);
  useEffect(() => {
    localStorage.setItem(
      "lastCustomiseMenuList",
      JSON.stringify(customiseMenuList)
    );
  }, [customiseMenuList]);

  //!Useefect to throttle search value in global
  useEffect(() => {
    const timeout = setTimeout(() => {
      setCurrentSearchText(searchInput);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [searchInput]);

  //! useefect to reset search when selected tab menu changes
  useEffect(() => {
    setSearchInput("");
  }, [currentSelectedMainMenu]);

  const location = useLocation();

  const currentRouteName = location.pathname.replace("/", "");

  const displayShareBtn = window.location.href.toLowerCase().includes("project") && projectId;

  const rootMenuContaineranimatedVariants = useMemo(
    () => ({
      initial: {
        y: -100,
        opacity: 0,
      },
      entering: {
        y: 0,
        opacity: 1,
        transition: {
          duration: 0.4,
          delay: 0.3,
        },
      },
    }),
    []
  );

  let lastLoginDate = moment(user?.lastLogin).format("MMM Do YYYY");
  const index = lastLoginDate.indexOf(" ", lastLoginDate.indexOf(" ") + 1);
  lastLoginDate = lastLoginDate.slice(0, index) + "," + lastLoginDate.slice(index);

  if (currentRouteName !== "AddProject")
    return (
      <StyledDoxleTopMenuContainer
        $themeColor={doxleThemeColor}
        $currentTheme={currentTheme}
        $position={currentMenuPostion}
        variants={rootMenuContaineranimatedVariants}
        initial="initial"
        animate="entering"
      >
        <StyledMenuLeftSection>
          {/* <DoxleAppDropDown />  */}
          <NavBarEllipsis />
        </StyledMenuLeftSection>

        <StyledDoxleGlobalSearchTextField
          $themeColor={doxleThemeColor}
          $doxleFont={doxleFont}
          $currentTheme={currentTheme}
          variant="standard"
          value={searchInput}
          onChange={(event) => setSearchInput(event.target.value)}
          placeholder={"Search"}
          InputProps={{
            startAdornment: (
              <StyledInputEndornment position="start">
                <TopMenuSearchIcon theme={currentTheme} />
              </StyledInputEndornment>
            ),
          }}
        />
        <StyledMenuRightSection>
          <StyledMenuThemeAndEllipsis
            $themeColor={doxleThemeColor}
            $doxleFont={doxleFont}
          >

          </StyledMenuThemeAndEllipsis>
        </StyledMenuRightSection>
      </StyledDoxleTopMenuContainer>
    );
  else return <></>;
};

export default DoxleTopMenu;
