import React, { useState } from "react";
import DoxleIconButton from "../DoxleButtons/DoxleIconButton";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import CloseFullscreenOutlinedIcon from "@mui/icons-material/CloseFullscreenOutlined";
import {ExpandSideScreenIcon} from "../../CoreContent/DoxleTopMenu/NavBarEllipsis/Asset/SideMenuIcons";
type Props = {
  expandToggleFunction: () => void;
  minimizeToggleFunction: () => void;
};

const ScreenSizeControl = ({
  expandToggleFunction,
  minimizeToggleFunction,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const doxleThemeColor = useDoxleThemeStore(
    (state) => state.doxleThemeColor,
    shallow
  );

  const handleClickBtn = () => {
    if (isExpanded) {
      minimizeToggleFunction();
      setIsExpanded(false);
    } else {
      expandToggleFunction();
      setIsExpanded(true);
    }
  };
  return (
    <DoxleIconButton
      iconSource={
        !isExpanded ? (
          // <OpenInFullOutlinedIcon
          //   style={{
          //     fontSize: "2rem",
          //     color: doxleThemeColor.primaryFontColor,
          //   }}
          // />
          <ExpandSideScreenIcon doxleTheme={doxleThemeColor} height={19}/>
        ) : (
          <CloseFullscreenOutlinedIcon
            style={{
              fontSize: "2rem",
              color: doxleThemeColor.primaryFontColor,
            }}
          />
        )
      }
      buttonSize={30}
      onClick={handleClickBtn}
    />
  );
};

export default ScreenSizeControl;
