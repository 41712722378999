import Cookie from "universal-cookie"
import {CookieSetOptions} from "universal-cookie/cjs/types";

const cookie = new Cookie();

class CookieServiceClass {
    get(key: string){
        return cookie.get(key)
    }

    set(key: string, value: string, options: CookieSetOptions){
        return cookie.set(key, value, options)
    }

    remove(key: string){
        return cookie.remove(key, {path: '/'})
    }
}

const CookieService = new CookieServiceClass();

export default CookieService