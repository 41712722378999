export interface Bill {
  billId: string;
  status: string;
  fileName: string;
  invoiceNumber: string|null;
  supplierId: string|null;
  supplierName: string|null;
  supplierAbn: string|null;
  supplierAddress: string|null;
  supplierPhone: string|null;
  billTotal: string|null;
  billSubtotal: string|null;
  billTax: string|null;
  billRounding: string|null;
  billDueAmount: string|null;
  issueDate: string|null;
  dueDate: string|null;
  isTaxExclusive: string|null;
  accountCode: string|null;
  trackingCategory: string|null;
  trackingOption: string|null;
  supplierBankName: string|null;
  supplierBsb: string|null;
  supplierBankAccount: string|null;
  thumbnail: string;
  numPages: number;
  lines?: BillLine[];
  pages?: BillPage[];
}

export type PatchBill = Pick<Partial<Omit<Bill, 'lines'|'pages'|'billRounding'|'billSubtotal'|'billTax'>>, 'billId'>

export interface BillLine {
  billLineId: string;
  index: number;
  description: string;
  quantity: string;
  unit: string;
  itemCost: string;
  lineCost: string;
  isTaxed: string;
  account: string|null;
  trackingOption: string|null;
  billId: string;
}

export type PatchBillLine = Pick<Partial<BillLine>, 'billLineId'>
export type AddBillLine = Pick<Partial<BillLine>, 'billId'|'description'>

interface BillPage {
  pageId: number;
  index: number;
  url: string;
}

export const blankBill = { billId: "", status: "", fileName: "", invoiceNumber: "", supplierId: null, supplierName: null,
  supplierAbn: null, supplierAddress: null, supplierPhone: null, billTotal: null, billSubtotal: null,  billTax: null,
  issueDate: null, dueDate: null, isTaxExclusive: null, accountCode: null, trackingCategory: null, trackingOption: null,
  supplierBankName: null, supplierBsb: null, supplierBankAccount: null, thumbnail: '', numPages: 0, billRounding: null,
  billDueAmount: null}
