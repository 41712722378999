import { createContext, useContext } from "react";
import {useDoxleCurrentContextStore} from "../../DoxleGeneralStore/useDoxleCurrentContext";
import {shallow} from "zustand/shallow";
import useGetCompanyList from "../../Services/QueryHooks/useGetCompanyList";
import {Company} from "../../Models/company";

type Props = {};
export interface ICompanyContextValue {}

const CompanyContext = createContext({});
const CompanyProvider = (children: any) => {
  const currentCompany = useDoxleCurrentContextStore(state => state.currentCompany, shallow)
  useGetCompanyList({enabled: !Boolean(currentCompany)});
  const companyContextValue: ICompanyContextValue = {};
  return <CompanyContext.Provider {...children} value={companyContextValue} />;
};
const useCompany = () => useContext(CompanyContext);
export { CompanyProvider, useCompany };
