import styled from "styled-components";
import {
  DoxleFont,
  DoxleTheme,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import { TRgbaFormat, editRgbaAlpha } from "../../Utilities/FunctionUtilities";
import { motion } from "framer-motion";

export const StyledDoxleSideMenuContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $currentTheme: DoxleTheme;
}>`
  width: calc(100% - 16px);
  height: calc(100% - 28px);
  background-color: ${(p) =>
    p.$currentTheme === "light"
      ? "rgba(255,255,255,1)"
      : p.$themeColor.primaryContainerColor};
  box-shadow: 0px -2px 8px 2px ${(p) => p.$themeColor.primaryBoxShadowColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 14px 8px;
`;
export const StyledDoxleSideScreenTopBannerContainer = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: calc(100% - 60px);
  margin-right: 40px;
  padding: 0px 10px;
  display: flex;
  align-items: center;

  span {
    margin-left: 14px;
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor as TRgbaFormat,
        alpha: "0.6",
      })};
    font-size: 8px;
    font-family: ${(p) => p.$doxleFont.secondaryTitleFont};
    font-style: normal;
    font-weight: 400;
    line-height: 120.8%;
    flex: 1;
    text-align: left;
  }
`;

export const StyledSideMenuContentContainer = styled.div`
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
`;
export const StyledMenuListSection = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $hasBorderBottom?: boolean;
}>`
  margin-top: 20px;
  padding-bottom: 18px;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  border-bottom: ${(p) => (p.$hasBorderBottom ?? true ? 1 : 0)}px solid
    ${(p) => p.$themeColor.primaryDividerColor};
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;
export const StyledSectionTitleText = styled.span<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$themeColor.primaryFontColor as TRgbaFormat,
      alpha: "0.7",
    })};
  font-size: 12px;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-style: normal;
  font-weight: 600;
  line-height: 118.9%;
  letter-spacing: -0.24px;
  margin-bottom: 8px;
  margin-left: 10px;
`;
export const StyledDoxleSideMenuItemContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
}>`
  width: calc(100% - 20px);
  display: flex;
  margin: 2px 0px;
  justify-content: space-between;
  align-items: center;
  padding: 4px 10px;
  cursor: pointer;
  &:hover {
    background-color: ${(p) => p.$themeColor.hoverColor};
    transition: 0.4s;
  }
  border-radius: 3px;
`;
export const StyledSelectCompanyHeaderSection = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 0.5rem;
  span {
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor as TRgbaFormat,
        alpha: "0.7",
      })};
    font-size: 14px;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 500;
    line-height: 118.9%;
    letter-spacing: -0.28px;
    text-transform: capitalize;
  }
`;

export const StyledItemIconAndLabelSection = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $currentTheme: DoxleTheme;
  $disableBackground?: boolean;
}>`
  display: flex;
  flex-direction: row;
  padding: 8px 12px;
  border-radius: 2px;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 3px;
  margin-bottom: 10px;
  height: 30px;
  &:hover {
    ${(p) =>
      p.$disableBackground
        ? `background-color: ${p.$themeColor.primaryHoverColor};`
        : "cursor: default;"}
  }
  ${(p) =>
    p.$disableBackground
      ? `border: 1px solid ${p.$themeColor.primaryDividerColor};`
      : `background-color: ${
          p.$currentTheme !== "dark"
            ? "rgba(241, 242, 245, 1)"
            : "rgba(255,255,255,0.8)"
        };`}

  span {
    color: ${(p) =>
      p.$disableBackground
        ? p.$themeColor.primaryFontColor
        : "rgba(0, 0, 0, 0.7)"};
    font-size: 14px;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 500;
    line-height: 118.9%;
    letter-spacing: -0.28px;
    text-transform: capitalize;
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .spinner-container {
    overflow: hidden
  }
`;

export const StyledItemIconAndLabelWrapper = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  border-bottom: 1px solid ${(p) => p.$themeColor.primaryDividerColor};
  padding-top: 10px;
`;

export const StyledSettingMenuItem = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  padding: 4px 0px 4px 30px;
  cursor: pointer;
  margin: 2px 0px;
  width: calc(100% - 30px);
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${(p) =>
    editRgbaAlpha({
      rgbaColor: p.$themeColor.primaryFontColor as TRgbaFormat,
      alpha: "0.7",
    })};
  font-size: 14px;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  font-style: normal;
  font-weight: 500;
  line-height: 118.9%;
  letter-spacing: -0.28px;
  text-transform: capitalize;
  &:hover {
    background-color: ${(p) => p.$themeColor.hoverColor};
    transition: 0.4s;
  }
  border-radius: 3px;
`;
export const StyledThemeSettingItemContainer = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledExpandThemeMenuSection = styled(motion.div)`
  width: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  margin: 4px 0px;
`;

export const StyledThemeMenuItem = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  width: calc(100% - 30px);
  display: flex;
  padding-left: 30px;
  position: relative;
  margin: 2px 0px;
  align-items: center;
  cursor: pointer;
  span {
    width: 80px;
    color: ${(p) =>
      editRgbaAlpha({
        rgbaColor: p.$themeColor.primaryFontColor as TRgbaFormat,
        alpha: "0.7",
      })};
    font-size: 14px;
    font-family: ${(p) => p.$doxleFont.primaryFont};
    font-style: normal;
    font-weight: 500;
    line-height: 118.9%;
    letter-spacing: -0.28px;
    text-transform: capitalize;
    text-align: left;
  }
  &:hover {
    span {
      color: ${(p) =>
        editRgbaAlpha({
          rgbaColor: p.$themeColor.primaryFontColor as TRgbaFormat,
          alpha: "0.4",
        })};
      transition: 0.4s;
    }
  }
`;

export const StyledChangeFontContainer = styled.div`
`;


export const StyledChangeFontTitle = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
font-size: 15px;
color: ${(p) => p.$themeColor.primaryFontColor};
border-bottom: 1px solid ${(p) => p.$themeColor.borderColor};
padding-bottom: 10px;
/* font-family: ${(p) => p.$doxleFont.primaryFont}; */
height: 10px;
display: flex;
margin-top: 10px;
`;

export const StyledSelectFontContainer = styled.div`
display: flex;
flex-direction: column;
align-items: center;
margin-top: 40px;
`;

export const StyledChangeFontText = styled.div`
display: flex;
justify-content: center;
align-items: center;
width: 100px;
margin-left: 2px;
`;

export const StyledFontItemContainer = styled.div`
width: fit-content;
margin-bottom: 20px;
cursor: pointer;
`;

export const StyledFontStyleDisplay = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
  $font: string;
}>`
font-size: 24px;
color: ${(p) => p.$themeColor.primaryFontColor};
border: 1px solid ${(p) => p.$themeColor.borderColor};
border-radius: 3px;
width: 49px;
height: 47px;
display: flex;
align-items: center;
justify-content: center;
font-family: ${(p) => p.$font};
`;

export const StyledFontName = styled.div<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
font-size: 14px;
color: ${(p) => p.$themeColor.primaryFontColor};
margin-top: 5px;
width: 100%;
text-align: center;
font-family: ${(p) => p.$doxleFont.primaryFont};
height: 17px;
width: 50px;
display: flex;
justify-content: center;
align-items: center;
`;

export const StyledBackButton = styled.button<{
  $themeColor: DoxleThemeColor;
  $doxleFont: DoxleFont;
}>`
  /* position: absolute;
  top: 0; */
  width: 70px;
  height: 30px;
  border-radius: 4px;
  border: ${(p) => p.$themeColor.borderColor};
  background-color: ${(p) => p.$themeColor.primaryFontColor};
  cursor: pointer;
  font-size: 14px;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  color: ${(p) => p.$themeColor.primaryReverseFontColor};
  position: relative;
  bottom: 15px;
  left: 50px;
`;