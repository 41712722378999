import styled from "styled-components";
import {
  DoxleFont,
  DoxleThemeColor,
} from "../../DoxleGeneralStore/useDoxleThemeStore";
import { motion } from "framer-motion";
import { TRgbaFormat, editRgbaAlpha } from "../../Utilities/FunctionUtilities";

export const StyledSideScreenRootContainer = styled(motion.div)<{
  $themeColor: DoxleThemeColor;
  //   widthInPixel?: `${number}px`;
  //   widthInRatio?: `${number}%`;
  $leftPosInPixel?: `${number}px`;
  $leftPosInRatio?: `${number}%`;
  $rightPosInPixel?: `${number}px`;
  $rightPosInRatio?: `${number}%`;
  $minWidthInPixel?: `${number}px`;
  $minWidthInRatio?: `${number}%`;
}>`
  position: absolute;
  height: 100%;
  top: 0;
  z-index: 10;
  display: flex;
  outline: none;
  justify-content: center;
  align-items: center;
  ${(p) =>
    p.$leftPosInPixel || p.$leftPosInRatio
      ? `left: ${p.$leftPosInPixel ? p.$leftPosInPixel : p.$leftPosInRatio};`
      : `right: ${
          p.$rightPosInPixel
            ? p.$rightPosInPixel
            : p.$rightPosInRatio
            ? p.$rightPosInRatio
            : 0
        };`}

  min-width: ${(p) =>
    p.$minWidthInPixel
      ? p.$minWidthInPixel
      : p.$minWidthInRatio
      ? p.$minWidthInRatio
      : "300px"};
`;
export const StyledToggleButtonSection = styled(motion.div)`
  position: absolute;
  top: 5px;
  display: flex;
  flex-direciton: row;
  z-index: 1000;
  align-items: center;
`;
