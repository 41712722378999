import {DoxleFont, DoxleTheme, DoxleThemeColor} from "../../../DoxleGeneralStore/useDoxleThemeStore";
import {styled} from "styled-components";
interface Coordinates {x:number; y:number}
export const StyledCompanyDropDown = styled.div<{
  $themeColor: DoxleThemeColor
  $doxleFont: DoxleFont
  $currentTheme: DoxleTheme
  $coordinates: Coordinates
}>`
  position: fixed;
  top: ${(p)=> p.$coordinates.y}px;
  left: ${(p)=> p.$coordinates.x}px;
  background-color: ${(p)=> p.$themeColor.primaryContainerColor};
  border-radius: 0 8px 0 8px;
  display: flex;
  flex-direction: column;
  padding: 8px;
  z-index: 50;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
  max-height: 450px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`

export const StyledCompanyDropDownItem = styled.div<{
  $themeColor: DoxleThemeColor
  $doxleFont: DoxleFont
  $currentTheme: DoxleTheme
}>`
  background-color: ${(p)=> p.$themeColor.primaryContainerColor};
  border-radius: 0;
  width: 100%;
  border-bottom: 1px solid ${(p)=> p.$themeColor.primaryDividerColor};
  color: ${(p)=> p.$themeColor.primaryFontColor};
  font-family: ${(p)=> p.$doxleFont.primaryFont};
  font-size: 14px;
  cursor: pointer;
  &:hover {
    background-color:  ${(p)=> p.$themeColor.primaryHoverColor};
    border-radius: 0 8px 0 8px;
    border-bottom: 1px solid ${(p)=> p.$themeColor.primaryHoverColor};
  }
  padding: 4px;
`

export const StyledDropDownContainer= styled.div<{
  $themeColor: DoxleThemeColor
  $doxleFont: DoxleFont
  $currentTheme: DoxleTheme
  $top: number
  $left: number
}>`
  position: fixed;
  top: ${(p) => p.$top}px;
  left: ${(p) => p.$left}px;
  background-color:  ${(p) => p.$themeColor.primaryBackgroundColor};
  box-shadow: 0px 0px 4px 0px ${(p) => p.$themeColor.primaryBoxShadowColor};
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 0 0.5rem 0.5rem 0.5rem;
`
export const StyledCompanyOptionsItem = styled.div<{
  $themeColor: DoxleThemeColor
  $doxleFont: DoxleFont
  $currentTheme: DoxleTheme
}>`
  width: calc(100% - 4rem);
  cursor: pointer;
  border-bottom: 1px solid ${(p) => p.$themeColor.primaryHoverColor};
  padding: 1.5rem 2rem;
  font-size: 13px;
  font-family: ${(p) => p.$doxleFont.primaryFont};
  line-height: 15px;
  color: ${(p) => p.$themeColor.primaryFontColor};
  display: flex;
  align-items: center;
  &:hover {
    background-color: ${(p) => p.$themeColor.primaryHoverColor};
  }
`

export const StyledOptions = styled.div<{
}>`
  margin-left: 0.5rem;
`
