import React, { useCallback, useEffect, useMemo } from "react";
import Modal from "@mui/base/Modal";
import { Backdrop, Slide } from "@mui/material";
import {
  StyledSideScreenRootContainer,
  StyledToggleButtonSection,
} from "./StyledComponentsDoxleSideScreen";
import { useDoxleThemeStore } from "../../DoxleGeneralStore/useDoxleThemeStore";
import { shallow } from "zustand/shallow";
import DoxleIconButton from "../DoxleButtons/DoxleIconButton";
import { useAnimate } from "framer-motion";
import ScreenSizeControl from "./ScreenSizeControl";
import LastPageIcon from "@mui/icons-material/LastPage";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import {CloseSideScreenIcon} from "../../CoreContent/DoxleTopMenu/NavBarEllipsis/Asset/SideMenuIcons";
type Props = {
  openSideScreen: boolean;
  handleCloseSideScreen: Function;
  hasBackDrop?: boolean;
  containerWidthInPixel?: `${number}px`;
  containerWidthInRatio?: `${number}%`;
  containerLeftPosInPixel?: `${number}px`;
  containerLeftPosInRatio?: `${number}%`;
  containerRightPosInPixel?: `${number}px`;
  containerRightPosInRatio?: `${number}%`;
  containerMinWidthInPixel?: `${number}px`;
  containerMinWidthInRatio?: `${number}%`;
  backdropStyle?: React.CSSProperties;
  transitionDirection?: "left" | "up" | "right" | "down";
  contentWrapperStyled?: React.CSSProperties;
  children: React.ReactNode;
  expandable?: boolean;

  onExpanded?: (expandValue: boolean) => void; //!FUNCTION TO GET EXPANDED STATE VALUE OF THE SIDE SCREEN
};

const DoxleSideScreen = ({
  openSideScreen,
  handleCloseSideScreen,
  hasBackDrop = false,
  containerWidthInPixel,
  containerWidthInRatio,
  containerLeftPosInPixel,
  containerLeftPosInRatio,
  containerMinWidthInPixel,
  containerMinWidthInRatio,
  containerRightPosInPixel,
  containerRightPosInRatio,
  backdropStyle,
  transitionDirection,
  children,
  expandable = true,
  onExpanded,
  contentWrapperStyled,
}: Props) => {
  const doxleThemeColor = useDoxleThemeStore(
    (state) => state.doxleThemeColor,
    shallow
  );
  const [scope, animate] = useAnimate();
  const initialRootContainerWidth = useMemo(
    () =>
      containerWidthInPixel
        ? containerWidthInPixel
        : containerWidthInRatio
        ? containerWidthInRatio
        : "50%",
    [containerWidthInPixel, containerWidthInRatio]
  );
  const fullScreenContainerWidth = useMemo(
    () =>
      containerWidthInPixel ? "100vw" : containerWidthInRatio ? "100%" : "100%",
    [containerWidthInPixel, containerWidthInRatio]
  );

  const [modalControlButtonScope, animateModalControlButtonScope] =
    useAnimate();
  const onClickExpandBtn = useCallback(() => {
    animate(scope.current, { width: fullScreenContainerWidth });
    animateModalControlButtonScope(modalControlButtonScope.current, {
      left: 0,

      transition: { duration: 0.4, delay: 1 },
    });
    if (onExpanded) onExpanded(true);
  }, [fullScreenContainerWidth, scope.current]);

  const onClickMinimizeBtn = useCallback(() => {
    animate(scope.current, { width: initialRootContainerWidth });
    animateModalControlButtonScope(modalControlButtonScope.current, {
      opacity: 1,

      transition: { duration: 0.4, delay: 1 },
    });
    if (onExpanded) onExpanded(false);
  }, [initialRootContainerWidth, scope.current]);

  return (
    <Modal
      open={openSideScreen}
      style={{
        position: "fixed",
        zIndex: 1000,
        inset: 0,
        display: "flex",
        justifyContent: "flex-end",
        height: "100%",
        width: "100%",
      }}
      disableEscapeKeyDown
      closeAfterTransition
      onClose={(ev, reason) => {
        if (reason !== "escapeKeyDown") handleCloseSideScreen();
      }}
      slots={{ backdrop: hasBackDrop ? Backdrop : undefined }}
      slotProps={{
        backdrop: {
          style: backdropStyle ? backdropStyle : undefined,
        },
      }}
    >
      <Slide
        direction={
          containerLeftPosInPixel || containerLeftPosInRatio
            ? "right"
            : containerRightPosInPixel || containerRightPosInRatio
            ? "left"
            : transitionDirection
            ? transitionDirection
            : "left"
        }
        in={openSideScreen}
        mountOnEnter
        unmountOnExit={true}
      >
        <StyledSideScreenRootContainer
          $themeColor={doxleThemeColor}
          //   widthInPixel={containerWidthInPixel}
          //   widthInRatio={containerWidthInRatio}
          $leftPosInPixel={containerLeftPosInPixel}
          $leftPosInRatio={containerLeftPosInRatio}
          $minWidthInPixel={containerMinWidthInPixel}
          $minWidthInRatio={containerMinWidthInRatio}
          $rightPosInPixel={containerRightPosInPixel}
          $rightPosInRatio={containerRightPosInRatio}
          layout
          ref={scope}
          style={{ width: initialRootContainerWidth }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              position: "relative",
              ...contentWrapperStyled,
            }}
          >
            <StyledToggleButtonSection
              ref={modalControlButtonScope}
              style={
                containerLeftPosInPixel || containerLeftPosInRatio
                  ? {
                      right: 0,
                    }
                  : containerRightPosInPixel ||
                    containerRightPosInRatio ||
                    (!containerLeftPosInPixel &&
                      !containerLeftPosInRatio &&
                      !containerRightPosInPixel &&
                      !containerRightPosInRatio)
                  ? { left: 0 }
                  : { left: 0 }
              }
            >
              <DoxleIconButton
                iconSource={
                  containerLeftPosInPixel || containerLeftPosInRatio ? (
                    <FirstPageIcon
                      style={{
                        fontSize: "3rem",
                        color: doxleThemeColor.primaryFontColor,
                      }}
                    />
                  ) : containerRightPosInPixel ||
                    containerRightPosInRatio ||
                    (!containerLeftPosInPixel &&
                      !containerLeftPosInRatio &&
                      !containerRightPosInPixel &&
                      !containerRightPosInRatio) ? (
                    // <LastPageIcon
                    //   style={{
                    //     fontSize: "3rem",
                    //     color: doxleThemeColor.primaryFontColor,
                    //   }}
                    // />
                    <CloseSideScreenIcon doxleTheme={doxleThemeColor} height={18}/>
                  ) : (
                    <LastPageIcon
                      style={{
                        fontSize: "3rem",
                        color: doxleThemeColor.primaryFontColor,
                      }}
                    />
                  )
                }
                buttonSize={30}
                onClick={() => handleCloseSideScreen()}
                style={{marginLeft: "1.5rem"}}
              />
              {expandable && (
                <ScreenSizeControl
                  expandToggleFunction={onClickExpandBtn}
                  minimizeToggleFunction={onClickMinimizeBtn}
                />
              )}
            </StyledToggleButtonSection>
            {children}
          </div>
        </StyledSideScreenRootContainer>
      </Slide>
    </Modal>
  );
};

export default DoxleSideScreen;
