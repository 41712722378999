import { create } from "zustand";
export type DoxleTheme = "light" | "contrast" | "dark";
interface DoxleThemeStore {
  doxleThemeColor: DoxleThemeColor;
  doxleFont: DoxleFont;
  setTheme: (theme: DoxleTheme) => void;
  currentTheme: DoxleTheme;
  THEME_MENU: DoxleTheme[];
  initializeTheme: () => void;
  fontMode: TDoxleFontMode;
  setFontMode: (mode: TDoxleFontMode) => void;
  transparentMode: boolean;
  setTransparentMode: (value: boolean) => void;
}
export interface DoxleThemeColor {
  primaryFontColor: `rgba(${number},${number},${number},${number})`;
  primaryReverseFontColor: `rgba(${number},${number},${number},${number})`;
  primaryBackgroundColor: `rgba(${number},${number},${number},${number})`;
  primaryContainerColor: `rgba(${number},${number},${number},${number})`;
  primaryReverseContainerColor: `rgba(${number},${number},${number},${number})`;
  primaryBackdropColor: `rgba(${number},${number},${number},${number})`;
  primaryReverseBackdropColor: `rgba(${number},${number},${number},${number})`;
  lightDividerColor: `rgba(${number},${number},${number},${number})`;
  primaryDividerColor: `rgba(${number},${number},${number},${number})`;
  boldDividerColor: `rgba(${number},${number},${number},${number})`;
  bolderDividerColor: `rgba(${number},${number},${number},${number})`;
  primaryBoxShadowColor: `rgba(${number},${number},${number},${number})`;
  doxleColor: `rgba(${number},${number},${number},${number})`;
  doxleReverseColor: `rgba(${number},${number},${number},${number})`;
  errorColor: `rgba(${number},${number},${number},${number})`;
  successColor: `rgba(${number},${number},${number},${number})`;
  doxleColor2: `rgba(${number},${number},${number},${number})`;
  primaryInputPlaceholderColor: `rgba(${number},${number},${number},${number})`;
  primaryTextFieldBackgroundColor: `rgba(${number},${number},${number},${number})`;
  secondaryTextFieldBackgroundColor: `rgba(${number},${number},${number},${number})`;
  rowHoverColor: `rgba(${number},${number},${number},${number})`;
  hoverColor: `rgba(${number},${number},${number},${number})`;
  primaryHoverColor: `rgba(${number},${number},${number},${number})`;
  primaryTableRowColor: `rgba(${number},${number},${number},${number})`;
  rowBorderColor: `rgba(${number},${number},${number},${number})`;
  borderColor: `rgba(${number},${number},${number},${number})`;
  headerInputColor: `rgba(${number},${number},${number},${number})`;
  headerInputReverseColor: `rgba(${number},${number},${number},${number})`;
  skeletonColor: `rgba(${number},${number},${number},${number})`;
  MailSideScreenColor: `rgba(${number},${number},${number},${number})`;
  MailSideScreenColorReverseColor: `rgba(${number},${number},${number},${number})`;
}
export type TDoxleFontMode = "Inter" | "Serif" | "Mono" | "Default";
const DEFAULT_DOXLE_FONT: DoxleFont = {
  primaryFont: "Inter",
  primaryTitleFont: "IBM Plex Sans",
  subTitleFont: "IBM Plex Mono",
  secondaryFont: "Work Sans",
  secondaryTitleFont: "IBM Plex Mono",
  robotoMonoFont: "Roboto Mono",
  plexSanCondensed: "IBM Plex Sans Condensed",
  sarina: "Sarina",
};
const INTER_FONT_MODE: DoxleFont = {
  primaryFont: "Inter",
  primaryTitleFont: "Inter",
  subTitleFont: "Inter",
  secondaryFont: "Inter",
  secondaryTitleFont: "Inter",
  robotoMonoFont: "Inter",
  plexSanCondensed: "Inter",
  sarina: "Inter",
};
const SERIF_FONT_MODE: DoxleFont = {
  primaryFont: "IBM Plex Serif",
  primaryTitleFont: "IBM Plex Serif",
  subTitleFont: "IBM Plex Serif",
  secondaryFont: "IBM Plex Serif",
  secondaryTitleFont: "IBM Plex Serif",
  robotoMonoFont: "IBM Plex Serif",
  plexSanCondensed: "IBM Plex Serif",
  sarina: "IBM Plex Serif",
};
const MONO_FONT_MODE: DoxleFont = {
  primaryFont: "IBM Plex Mono",
  primaryTitleFont: "IBM Plex Mono",
  subTitleFont: "IBM Plex Mono",
  secondaryFont: "IBM Plex Mono",
  secondaryTitleFont: "IBM Plex Mono",
  robotoMonoFont: "IBM Plex Mono",
  plexSanCondensed: "IBM Plex Mono",
  sarina: "IBM Plex Mono",
};

const CONTRAST_COLOR: DoxleThemeColor = {
  primaryFontColor: "rgba(0, 0, 0, 1)",
  primaryReverseFontColor: "rgba(255, 255, 255,1)",
  primaryBackgroundColor: "rgba(241, 242, 245, 1)",
  primaryContainerColor: "rgba(255, 255, 255,1)",
  primaryReverseContainerColor: "rgba(7, 7, 10,1)",
  primaryBackdropColor: "rgba(0,0,0,0.2)",
  primaryReverseBackdropColor: "rgba(255,255,255,0.9)",
  lightDividerColor: "rgba(245,246,247,1)",
  primaryDividerColor: "rgba(211,213,225,1)",
  boldDividerColor: "rgba(209, 215, 222,1)",
  bolderDividerColor: "rgba(118, 125, 134,1)",
  errorColor: "rgba(255,6,6,0.7)",
  doxleColor: "rgba(82,82,255,1)",
  doxleColor2: "rgba(112,112,255,1)",
  doxleReverseColor: "rgba(255,100,152,0.55)",
  primaryBoxShadowColor: "rgba(128,128,128,0.2)",
  successColor: "rgba(18, 183, 24,1)",
  primaryInputPlaceholderColor: "rgba(175, 175, 199,0.6)",
  primaryTextFieldBackgroundColor: "rgba(247,247,247,1)",
  secondaryTextFieldBackgroundColor: "rgba(245,246,247,1)",
  rowHoverColor: "rgba(112,112,255,0.15)",
  hoverColor: "rgba(126, 150, 187, 0.12)",
  primaryHoverColor: "rgba(232,232,255,1)",
  primaryTableRowColor: "rgba(255, 255, 255, 1)",
  rowBorderColor: "rgba(0, 0, 0,0.1)",
  borderColor: "rgba(221, 221, 221, 1)",
  headerInputColor: "rgba(239, 240, 244, 1)",
  headerInputReverseColor: "rgba(41, 41, 41, 1)",
  MailSideScreenColor: `rgba(241, 242, 245, 1)`,
  MailSideScreenColorReverseColor: `rgba(0,0,0,0.9)`,
  skeletonColor: "rgba(239,240,241,1)",
};
const LIGHT_COLOR: DoxleThemeColor = {
  primaryFontColor: "rgba(0, 0, 0,1)",
  primaryReverseFontColor: "rgba(255, 255, 255,1)",
  primaryBackgroundColor: "rgba(245, 245, 247, 1)",
  primaryContainerColor: "rgba(250, 250, 252, 1)",
  primaryReverseContainerColor: "rgba(7, 7, 10,1)",
  primaryBackdropColor: "rgba(0,0,0,0.2)",
  primaryReverseBackdropColor: "rgba(255,255,255,0.9)",
  lightDividerColor: "rgba(230,230,232,1)",
  primaryDividerColor: "rgba(240,240,240,1)",
  boldDividerColor: "rgba(230, 230, 232,1)",
  bolderDividerColor: "rgba(118, 125, 134,1)",
  errorColor: "rgba(255,6,6,0.7)",
  doxleColor: "rgba(82,82,255,1)",
  doxleColor2: "rgba(112,112,255,1)",
  doxleReverseColor: "rgba(255,100,152,0.55)",
  primaryBoxShadowColor: "rgba(128,128,128,0.2)",
  successColor: "rgba(18, 183, 24,1)",
  primaryInputPlaceholderColor: "rgba(128,128,128,0.5)",
  primaryTextFieldBackgroundColor: "rgba(247,247,247,1)",
  secondaryTextFieldBackgroundColor: "rgba(245,246,247,1)",
  rowHoverColor: "rgba(112,112,255,0.15)",
  hoverColor: "rgba(126, 150, 187, 0.12)",
  primaryHoverColor: "rgba(232,232,255,1)",
  primaryTableRowColor: "rgba(255, 255, 255, 1)",
  rowBorderColor: "rgba(239,240,244,1)",
  borderColor: "rgba(221, 221, 221, 1)",
  headerInputColor: "rgba(239, 240, 244, 1)",
  headerInputReverseColor: "rgba(41, 41, 41, 1)",
  MailSideScreenColor: `rgba(241, 242, 245, 1)`,
  MailSideScreenColorReverseColor: `rgba(0,0,0,0.9)`,
  skeletonColor: "rgba(241,242,243,1)",
};
const DARK_COLOR: DoxleThemeColor = {
  primaryFontColor: "rgba(255, 255, 255,1)",
  primaryReverseFontColor: "rgba(0, 0, 0,1)",
  primaryBackgroundColor: "rgba(0,0,0,1)",
  primaryContainerColor: "rgba(0, 0, 0,1)",
  primaryReverseContainerColor: "rgba(255, 255, 255,1)",
  primaryBackdropColor: "rgba(255,255,255,0.2)",
  primaryReverseBackdropColor: "rgba(0,0,0,0.2)",
  lightDividerColor: "rgba(22,22,27,1)",
  primaryDividerColor: "rgba(45, 45, 49, 1)",
  boldDividerColor: "rgba(209, 215, 222,1)",
  bolderDividerColor: "rgba(239, 240, 244,0.8)",
  errorColor: "rgba(255,0,0,1)",
  doxleColor: "rgba(82,82,255,1)",
  doxleColor2: "rgba(112,112,255,1)",
  doxleReverseColor: "rgba(255,100,152,0.55)",
  primaryBoxShadowColor: "rgba(255,255,255,0)",
  successColor: "rgba(18, 183, 24,1)",
  primaryInputPlaceholderColor: "rgba(255,255,255,0.4)",
  primaryTextFieldBackgroundColor: "rgba(25, 25, 31, 1)",
  secondaryTextFieldBackgroundColor: "rgba(14,14,14,1)",
  rowHoverColor: "rgba(112,112,255,0.15)",
  hoverColor: "rgba(126, 150, 187, 0.12)",
  primaryHoverColor: "rgba(232,232,255,1)",
  primaryTableRowColor: "rgba(0, 0, 0, 1)",
  rowBorderColor: "rgba(0, 0, 0, 0.15)",
  borderColor: "rgba(255, 255, 255, 1)",
  headerInputColor: "rgba(41, 41, 41, 1)",
  headerInputReverseColor: "rgba(239, 240, 244, 1)",
  MailSideScreenColor: `rgba(0,0,0,0.9)`,
  MailSideScreenColorReverseColor: `rgba(241, 242, 245, 1)`,
  skeletonColor: "rgba(18, 18, 24, 1)",
};
export interface DoxleFont {
  primaryFont: string;
  primaryTitleFont: string;
  subTitleFont: string;
  secondaryFont: string;
  secondaryTitleFont: string;
  robotoMonoFont: string;
  plexSanCondensed: string;
  sarina: string;
}

export const useDoxleThemeStore = create<DoxleThemeStore>((set, get) => ({
  doxleFont: INTER_FONT_MODE,
  doxleThemeColor: CONTRAST_COLOR,

  setTheme: (theme: DoxleTheme) => {
    if (theme === "contrast") {
      set({
        doxleThemeColor: CONTRAST_COLOR,
        currentTheme: theme,
        transparentMode: false,
      });
    } else if (theme === "dark")
      set({
        doxleThemeColor: DARK_COLOR,
        currentTheme: theme,
        transparentMode: true,
      });
    else
      set({
        doxleThemeColor: LIGHT_COLOR,
        currentTheme: theme,
        transparentMode: true,
      });

    localStorage.setItem("lastTheme", theme);
  },
  currentTheme: "contrast",
  THEME_MENU: ["light", "contrast", "dark"],
  initializeTheme: () => {
    const lastThemeStorage = localStorage.getItem("lastTheme");
    if (lastThemeStorage) get().setTheme(lastThemeStorage as DoxleTheme);
    if ((lastThemeStorage as DoxleTheme) === "contrast")
      get().setTransparentMode(false);
  },
  fontMode: "Default",
  setFontMode: (mode: TDoxleFontMode) => {
    set({
      doxleFont:
        mode === "Default"
          ? DEFAULT_DOXLE_FONT
          : mode === "Inter"
          ? INTER_FONT_MODE
          : mode === "Serif"
          ? SERIF_FONT_MODE
          : MONO_FONT_MODE,
      fontMode: mode,
    });
  },

  transparentMode: true,
  setTransparentMode: (value: boolean) => {
    set({ transparentMode: value });
  },
}));
