import { styled } from "styled-components";
import { DoxleThemeColor } from "./DoxleGeneralStore/useDoxleThemeStore";

export const StyledAppContainer = styled.div<{
  $themeColor: DoxleThemeColor;
}>`
  // Page with Border
  //width: calc(100vw - 1.8rem);
  //height: calc(100vh - 0.9rem);
  //border: 0.9rem solid ${(p) => p.$themeColor.primaryContainerColor};
  //border-top: none;
  
  // Page without Border
  width: 100vw;
  height: 100vh;
  border: none;
  
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p.$themeColor.primaryBackgroundColor};
`;

export const StyledAppContentSection = styled.div`
  flex: 1;
  width: 100%;
  display: flex;

  justify-content: center;
  align-items: center;

  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
