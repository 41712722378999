//!NOTE IMPORTANT: remove this import in production build
// import "./wdyr";

import React, { useEffect } from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider } from "notistack";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import TimeAgo from "javascript-time-ago";

import en from "javascript-time-ago/locale/en.json";
import ru from "javascript-time-ago/locale/ru.json";
import { useDoxleThemeStore } from "./DoxleGeneralStore/useDoxleThemeStore";
import { useDoxleWindowStore } from "./DoxleGeneralStore/useDoxleWindowSizeStore";
import { shallow } from "zustand/shallow";
import { AxiosInterceptor } from "./Services/DoxleAPI";
import { Grow } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDoxleCurrentContextStore } from "./DoxleGeneralStore/useDoxleCurrentContext";
import TextOnlyNotification from "./DoxleDesignPattern/Notification/TextOnlyNotification";
import { AuthProvider } from "./CoreContent/Provider/AuthProvider";
import { CompanyProvider } from "./CoreContent/Provider/CompanyProvider";

TimeAgo.addDefaultLocale(en);
TimeAgo.addLocale(ru);
const queryClient = new QueryClient();
//!------->declare variant for notification, whenever show the notification, call the key name, the key name will match with the component assign to that
declare module "notistack" {
  interface VariantOverrides {
    //!pass extra props belong to the component of this key, in this case the component of key "textNotification" is TextOnlyNotification, refer to the component to see the props. Do the same with all other notification component to achieve desired result
    textNotification: {
      extraDescription?: string;
      messageType: "error" | "success";
    };
  }
}

//!<---------
function AppWithCallbackAfterRender() {
  const { initializeTheme } = useDoxleThemeStore((state) => ({
    initializeTheme: state.initializeTheme,
  }));
  const { initializeMenuPosition } = useDoxleCurrentContextStore(
    (state) => ({
      initializeMenuPosition: state.initializeMenuPosition,
    }),
    shallow
  );
  useEffect(() => {
    initializeTheme();
    initializeMenuPosition();
  }, []);

  const updateWindowValues = useDoxleWindowStore(
    (state) => state.updateValues,
    shallow
  );
  useEffect(() => {
    const handleResize = () => {
      updateWindowValues(window);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <React.StrictMode>
      <SnackbarProvider
        Components={{
          textNotification: TextOnlyNotification,
        }}
        preventDuplicate
      >
        <QueryClientProvider client={queryClient}>
          <BrowserRouter>
            <Routes>
              <Route
                path="*"
                element={
                  <AuthProvider>
                    <CompanyProvider>
                      <AxiosInterceptor>
                        <App />
                      </AxiosInterceptor>
                    </CompanyProvider>
                  </AuthProvider>
                }
              />
            </Routes>
          </BrowserRouter>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </SnackbarProvider>
    </React.StrictMode>
  );
}
const container = document.getElementById("root");
const root = createRoot(container!);

root.render(<AppWithCallbackAfterRender />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
